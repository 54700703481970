import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  doc,
  getDoc,
  getDocs,
  updateDoc,
  addDoc,
  collection,
  query,
  where,
  orderBy,
  limit,
  serverTimestamp,
} from "firebase/firestore/lite";
import { Button, Container } from "@mui/material";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import {
  BEGIN_TEST,
  FINISH,
  LISTENING_TEST,
  READING_TEST,
  REGION_DOMINIC_REPUBLICAN,
  REGION_GENERAL,
  REGION_INDIA,
  REGION_PHILLIPINES,
  VOCAB_TEST,
} from "./helper";
import AudioRecorder1 from "./AudioRecorder1";
import Banner from "./Banner";
import ListeningAssessment from "./ListeningAssessment";
import GrammerAssessment from "./GrammerAssessment";
import MySpinner from "./MySpinner";
import SoftBox from "./SoftBox";
import SoftButton from "./SoftButton";
import SoftTypography from "./SoftTypography";
import TagManager from "react-gtm-module";

const StyledButton = styled(Button)`
  flex-grow: 1;
  // position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

export default function Assessment() {

  const [passage1, setPassage1] = useState("");
  const [regions, setRegions] = useState([]);
  const [localStorageRegion, setLocalStorageRegion] = useState([]);

  const [isLoading, setIsLoading] = useState();
  const [questions, setQuestions] = useState([]);
  const [audioFiles, setAudioFiles] = useState([]);
  const [assessment, setAssessment] = useState();
  const [region, setRegion] = useState();
  const [currentStep, setCurrentStep] = useState(0);

  const [passage2, setPassage2] = useState("");
  const [passage3, setPassage3] = useState("");

  const [user, setUser] = useState();

  async function loadAudio(audioName, jsonName, fileName) {
    const audio = await import(`../../public/listening/${audioName}`);
    const json = await import(`../../public/listening/${jsonName}`);
    const jsonData = json.default.filter((item) => item.question !== "");
    return { audio_file: audio.default, json_file: jsonData, file_name: fileName };
  }

  const loadAudioFile = async (list) => {

    const shuffledList = [...list];
    for (let i = shuffledList.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledList[i], shuffledList[j]] = [shuffledList[j], shuffledList[i]];
    }
    // Now shuffledList is a shuffled version of list
    const list1 = shuffledList.slice(0, 1);

    const promises = list1.map((item) =>
      loadAudio(item + ".mp3", item + ".json", item)
    );
    const results = await Promise.all(promises);
    // Promise.all(promises).then((results) => {
    //   return results;
    // });
    return results
  }

  useEffect(() => {
    const list = ["room", "repairs", "flowershop", "expense"];
    const regionsData = [];
    const localStorageRegion = sessionStorage.getItem('region');
    setLocalStorageRegion(localStorageRegion)
    const regionRef = collection(db, "regions");
    getDocs(regionRef)
      .then(async (querySnapshot) => {
        querySnapshot.forEach((docSnapshot) => {
          regionsData.push(docSnapshot.data());
        });
        setRegions(regionsData);
      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      })

    loadAudioFile(list)

  }, []);

  let { q } = useParams();
  useEffect(() => {
    setIsLoading(true);
    const userRef = doc(db, "users", q);
    getDoc(userRef)
      .then(async (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          let isCreateNewAssessment = false;
          const q1 = query(
            collection(db, "assessments"),
            where("userId", "==", docSnapshot.id),
            orderBy("createdAt", "desc"),
            limit(1)
          );
          const querySnapshot = await getDocs(q1);
          if (querySnapshot.empty) {
            isCreateNewAssessment = true;
          } else {
            const assessement1 = querySnapshot.docs[0].data();
            if (assessement1.currentStep === "Finish") {
              isCreateNewAssessment = false;
              const stepIndex = steps.indexOf(assessement1.currentStep);
              setCurrentStep(stepIndex);

            } else {
              const stepIndex = steps.indexOf(
                assessement1.currentStep || BEGIN_TEST
              );
              
              assessement1.uid = querySnapshot.docs[0].id;
              setAssessment(assessement1);
              setQuestions(assessement1.vocabtext)
              setPassage1(assessement1.passage)
              if (assessement1.audio_file) {
                const audioFiles1 = await loadAudioFile([assessement1.audio_file])
                setAudioFiles(audioFiles1)
              }
              setRegion(assessement1.region)

              setCurrentStep(stepIndex);
            }
          }

          if (isCreateNewAssessment) {
            await createNewAssessment(userData)
          }
          setUser(userData);

          TagManager.dataLayer({
            dataLayer: {
              event: "component_loaded",
              component: "Assessment",
              userData: userData
            },
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [q]);

  const handleNextStep = (region) => {
    if (region !== null && region) {
      sessionStorage.removeItem('region')
      sessionStorage.removeItem('isRegion')
      try {
        setIsLoading(true)
        setRegion(region)

        const apiUrl = `${process.env.REACT_APP_SERVER_URL}/get_questions?region=${region}&assessment=${assessment.uid}`;
        const options = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        };

        fetch(apiUrl, options)
          .then(async (response) => response.json())
          .then(async (data) => {
            const questionsData = data.questionsData
            setQuestions(questionsData);
            setPassage1(data.readingPassagesData.passage1);

            const audioFiles1 = await loadAudioFile([data.listeningData.field])
            setAudioFiles(audioFiles1)

            setIsLoading(false)
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
            setIsLoading(false);
          });

      } catch (error) {
        console.log(error)
        setIsLoading(false)
      }

    }
    setCurrentStep((prevStep) => prevStep + 1);
    window.scrollTo(0, 0);
  };


  const createNewAssessment = async (userData) => {
    const assessment1 = {
      userId: userData.uid,
      createdAt: serverTimestamp(),
      currentStep: BEGIN_TEST,
    };
    const assessRef = collection(db, "assessments");

    addDoc(assessRef, assessment1)
      .then((docRef) => {
        assessment1.uid = docRef.id;
        setAssessment(assessment1);
        setCurrentStep(0);
        updateDoc(doc(collection(db, "users"), userData.uid), {
          currentStep: BEGIN_TEST,
          resultStatus: "Initial",
        }).then((userDocRef) => {
          console.log("Updated the user ", userDocRef);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
    // window.scrollTo(0, 0);
  };

  const steps = [BEGIN_TEST, VOCAB_TEST, READING_TEST, LISTENING_TEST, FINISH];

  const getButtons = () => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {currentStep > 0 ? (
          <StyledButton onClick={handlePrevStep}>Previous</StyledButton>
        ) : (
          <StyledButton style={{ visibility: "hidden" }}>Previous</StyledButton>
        )}
        {currentStep < 4 ? (

          <StyledButton onClick={() => handleNextStep()}>Next</StyledButton>
        ) : (
          <StyledButton style={{ visibility: "hidden" }}>Next</StyledButton>
        )}
      </div>
    );
  };

  const renderStep = () => {
    switch (steps[currentStep]) {
      case BEGIN_TEST:
        return (
          <>
            {user && (
              <Container>
                <SoftBox mt={1}>
                  <SoftTypography variant="h5" fontWeight="bold" color="dark">
                    Greetings, {`${user.firstName} ${user.lastName}`}
                  </SoftTypography>
                </SoftBox>

                <SoftBox mt={1}>
                  <SoftTypography variant="h6" fontWeight="medium" color="dark">
                    We are pleased to welcome you to our Candidate Assessment
                    platform, where you will undergo a comprehensive evaluation
                    of your skills and abilities.
                  </SoftTypography>
                </SoftBox>

                <p className="text-center"></p>
                <SoftBox mt={1}>
                  <SoftTypography variant="h6" fontWeight="medium" color="dark">
                    The assessment process consists of three tests. The first
                    test evaluates your English vocabulary and grammar through
                    multiple-choice questions. The second test involves
                    activating your browser audio and reading out a passage that
                    will be presented to you. Finally, the third test evaluates
                    your listening comprehension skills by presenting an audio
                    clips on different topics, followed by multiple-choice
                    questions.
                  </SoftTypography>
                </SoftBox>

                <p className="text-center"></p>
                <SoftBox mt={1}>
                  <SoftTypography variant="h6" fontWeight="medium" color="dark">
                    Before we get started, we recommend that you ensure a quiet
                    environment and a stable internet connection to ensure that
                    your assessment runs smoothly.
                  </SoftTypography>
                </SoftBox>
                <p className="text-center"></p>
                <SoftTypography variant="h6" fontWeight="medium" color="dark">
                  Please review the following details to ensure that your
                  account information is accurate <br />
                  <br />
                  {user.phoneNumber && (<>
                    Phone Number - {user.phoneNumber} <br />
                  </>)}
                  {user.email && (<>
                    Email - {user.email} <br />
                  </>)}
                </SoftTypography>

                {/* 
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="contained"
                    color="gt_primary"
                    onClick={() => handleNextStep(REGION_GENERAL)}
                    fullWidth
                  >
                    General assessment
                  </SoftButton>
                </SoftBox>
                 */}

                {localStorageRegion ? (
                  <SoftBox mt={4} mb={1}>
                    <SoftButton
                      variant="contained"
                      color="gt_primary"
                      onClick={() => handleNextStep(localStorageRegion)}
                      fullWidth
                    >
                      {regions.find((r) => r.region === localStorageRegion)?.assessment_name}
                    </SoftButton>
                  </SoftBox>
                ) : (
                  // Check if the region is not null, then only render the button for that specific region
                  region ? (
                    <SoftBox mt={4} mb={1}>
                      <SoftButton
                        variant="contained"
                        color="gt_primary"
                        onClick={() => handleNextStep(region)}
                        fullWidth
                      >
                        {regions.find((r) => r.region === region)?.assessment_name}
                      </SoftButton>
                    </SoftBox>
                  ) : (
                    // If no specific region, generate buttons for all regions
                    regions.map((regionData) => (
                      <SoftBox key={regionData.region} mt={4} mb={1}>
                        <SoftButton
                          variant="contained"
                          color="gt_primary"
                          onClick={() => handleNextStep(regionData.region)}
                          fullWidth
                        >
                          {regionData.assessment_name}
                        </SoftButton>
                      </SoftBox>
                    ))
                  )
                )}


                {/* <p className="text-center mt-3">
                  Please note that each test has a time limit, and you will need
                  to complete each test within the allotted time.
                </p> */}
              </Container>
            )}
          </>
        );
      case VOCAB_TEST:
        return (
          <GrammerAssessment
            user={user}
            assessment={assessment}
            handleNextStep={handleNextStep}
            region={region}
            questions={questions}
          />
        );
      case READING_TEST:
        return (
          <AudioRecorder1
            user={user}
            assessment={assessment}
            handleNextStep={handleNextStep}
            region={region}
            passage={passage1}
          />
        );
      case LISTENING_TEST:
        return (
          <ListeningAssessment
            audioFiles={audioFiles}
            user={user}
            assessment={assessment}
            region={region}
            handleNextStep={handleNextStep}
          />
        );
      case FINISH:
        return (
          <Container style={{ marginTop: "12px" }}>
            <SoftBox mt={1} mb={2}>
              <SoftTypography variant="body1" fontWeight="medium" color="dark">
                Thanks for taking the assessment, will get in touch soon.
              </SoftTypography>
            </SoftBox>

            <p></p>
            <Link to={`/review/${user.uid}`} style={{ textDecoration: "none" }}>
              <SoftButton variant="text" color="gt_primary">Review Your Assessment</SoftButton>
            </Link>
            <p></p>
            <SoftButton variant="text" color="gt_primary"
              onClick={() => createNewAssessment(user)}>
              Take new assessment
            </SoftButton>
          </Container>
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <div className="spinner">
        <MySpinner />
      </div>
    );
  }

  return (
    <div className="container">
      <Banner showBackButton={false} />
      <div className="row justify-content-center mt-5">
        <div className="col-md-8 col-sm-12">
          {user && (
            <div>{/* <h1>{`${user.firstName} ${user.lastName}`}</h1> */}</div>
          )}
          {renderStep()}
        </div>
      </div>

      {/* {getButtons()} */}
    </div>
  );
}
