import React, { useState } from "react";
import { db, secondAuth } from "../firebase";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button, Typography, Container, Card, CardContent, Select, MenuItem } from "@mui/material";
import styled from "styled-components";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { collection, setDoc, doc, serverTimestamp } from "firebase/firestore/lite";
import { useNavigate } from "react-router-dom";
import Banner from "./Banner";
import MySpinner from "./MySpinner";
import SoftBox from "./SoftBox";
import SoftTypography from "./SoftTypography";
import SoftInput from "./SoftInput";
import SoftButton from "./SoftButton";

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default function AddUser() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: ""
  });
  
  
  const history = useNavigate();
  const handleCancel = (e) => {
    history("/candidates");
  };

  
  const handleFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    createUserWithEmailAndPassword(secondAuth, formData.email, formData.password)
      .then((userCredential) => {
        const user = userCredential.user;
        const uid = user.uid;

        const newUser = {
          firstName : formData.firstName,
          lastName : formData.lastName,
          userType : 'Employee',
          phoneNumber: formData.phone,
          uid,
          email: formData.email,
          createdAt: serverTimestamp()
        };

        const usersRef = collection(db, "users");
        const userDocRef = doc(usersRef, uid);
        // Add the new user to the "users" collection in Firestore
        setDoc(userDocRef, newUser)
          .then((docRef) => {

            setFormData({})
            setLoading(false)
            setSuccess(true);
            setTimeout(() => {
              setSuccess(false);
            }, 3000);
          })
          .catch((error) => {
            console.error("Error adding user: ", error);
            setError("error adding user");
        setLoading(false);
          });
      })
      .catch((error) => {
        // Error creating user account
        console.error(error);
        setError("error adding user");
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div className="spinner">
        <MySpinner />
      </div>
    );
  }

  return (
    <>
    <Banner showBackButton={true} />
    <Container>
      {success && (
        <SoftBox mt={2} mb={2} textAlign="center">
          <h6
            style={{
              fontSize: ".8em",
              color: "red",
              textAlign: "center",
              fontWeight: 400,
              transition: ".2s all",
            }}
          >
            Employee added
          </h6>
        </SoftBox>
      )}

      <Card>
        <CardContent>
          <SoftBox pt={1} pb={2} >
            <SoftBox pt={3}>
              <SoftTypography variant="body2" fontWeight="medium">
                Add Candidate
              </SoftTypography>
            </SoftBox>

            <SoftBox
              component="form"
              role="form"
              onSubmit={handleSubmit}
              display="flex" flexWrap="wrap" 
              justifyContent="space-between"
              style={{
                display: "flex",
                flexDirection: "column",
                // gridTemplateColumns: isMobile ? "1fr" : "repeat(6, 1fr)",
                // gap: "1rem",
              }}
            >
              <SoftBox sx={{ width: '100%', mb: 2 }} xs={12} sm={6} md={6} lg={4} xl={4}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    First Name
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="text"
                  name="firstName"
                  value={formData?.firstName}
                  onChange={handleFormData}
                  placeholder="First Name"
                />
              </SoftBox>
              <SoftBox sx={{ width: '100%', mb: 2 }} xs={12} sm={6} md={6} lg={4} xl={4}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Last Name
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="text"
                  name="lastName"
                  onChange={handleFormData}
                  placeholder="Last Name"
                  value={formData?.lastName}
                />
              </SoftBox>

              <SoftBox sx={{ width: '100%', mb: 2 }} xs={12} sm={6} md={6} lg={4} xl={4}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Region
                  </SoftTypography>
                </SoftBox>
                <Select
              // onChange={(event) => handleFilterChange(event, false, true)}
              style={{
                fontSize: "1rem",
                color: "inherit",
                paddingRight: "1px !important",
              }}
              // sx={{
              //   "& .MuiSelect-select": {
              //     minWidth: isMobile ? "100%" : "200px",
              //   },
              // }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="IND">India</MenuItem>
              <MenuItem value="DR">Dominican Republic</MenuItem>
              <MenuItem value="PHIL">Phillippines</MenuItem>
            </Select>
              </SoftBox>

              <SoftBox sx={{ width: '100%', mb: 2 }} xs={12} sm={6} md={6} lg={4} xl={4}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Phone
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="phone"
                  name="phone"
                  onChange={handleFormData}
                  placeholder="Phone"
                  value={formData?.phone}
                />
              </SoftBox>

              <SoftBox sx={{ width: '100%', mb: 2 }} xs={12} sm={6} md={6} lg={4} xl={4}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Email
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="email"
                  name="email"
                  onChange={handleFormData}
                  placeholder="Email"
                  value={formData?.email}
                />
              </SoftBox>

              <SoftBox sx={{ width: '100%', mb: 2 }} xs={12} sm={6} md={6} lg={4} xl={4}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Password
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="password"
                  name="password"
                  onChange={handleFormData}
                  placeholder="Password"
                  value={formData?.password}
                />
              </SoftBox>

              <SoftBox mt={2} mb={2} textAlign="center">
                <h6
                  style={{
                    fontSize: ".8em",
                    color: "red",
                    textAlign: "center",
                    fontWeight: 400,
                    transition: ".2s all",
                  }}
                >
                  {error}
                </h6>
              </SoftBox>
              <SoftBox mt={2} mb={1}>
                <SoftButton
                  variant="contained"
                  color="gt_primary"
                  onClick={handleSubmit}
                  fullWidth
                >
                  Add Employee
                </SoftButton>
              </SoftBox>

              <SoftBox mt={4} mb={1}>
                <SoftButton
                  onClick={handleCancel}
                  fullWidth
                >
                  Cancel
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </CardContent>
      </Card>
    </Container>

    <FormWrapper onSubmit={handleSubmit}>
      <Banner />
      <Typography variant="h4" component="h2">
        Add New User
      </Typography>
      <TextField
        label="First Name"
        value={formData.firstName}
        onChange={handleFormData}
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Last Name"
        value={formData.lastName}
        onChange={handleFormData}
        margin="normal"
        variant="outlined"
      />
      <TextField
        type="email"
        label="Email"
        value={formData.email}
        onChange={handleFormData}
        />
      <TextField
        type="password"
        label="Password"
        value={formData.password}
        onChange={handleFormData}
        />
      <TextField
        label="Phone Number"
        value={formData.phoneNumber}
        onChange={handleFormData}
        margin="normal"
        variant="outlined"
      />
      <Button variant="contained" color="primary" type="submit">
        Add User
      </Button>
      <Button variant="outlined" color="secondary" onClick={handleCancel}>
        Cancel
      </Button>
    </FormWrapper>
    </>
    
  );
}
