import React, { useState, useEffect, useRef, useCallback } from "react";
import debounce from 'lodash.debounce';
import {
  Card,
  CardContent,
  Typography,
  Container,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  TextField,
  IconButton,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import ExcelJS from "exceljs";
import CircularProgress from "@mui/material/CircularProgress";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  Timestamp,
} from "firebase/firestore/lite";
import SearchBar from "./SearchBar";
import { Link, useNavigate } from "react-router-dom";
import "../style.css";
import Banner from "./Banner";
import InfiniteScroll from "react-infinite-scroll-component";
import SoftBox from "./SoftBox";
import Bill from "./Bill";
import SoftButton from "./SoftButton";
import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css";
import DatePicker from "@amir04lm26/react-modern-calendar-date-picker";
import SoftTypography from "./SoftTypography";
import CloseIcon from "@material-ui/icons/Close";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import MiniStatisticsCard from "./Cards/StatisticsCards/MiniStatisticsCard";
import { RotatingLines } from "react-loader-spinner";
import MySpinner from "./MySpinner";
import updateUserAssessment from "../globals/db-updates"
import { getTotalTime } from "../globals/utils";
const CardLayout1 = ({ row }) => {
  const { resultStatus } = row;
  let status = "";

  let showCodeGenerated = false;
  switch (resultStatus) {
    case "Screened":
      status = "Screened";
      break;
    case "Passed":
      status = "Ops Screened - Pass";
      break;
    case "Rejected":
      status = "Ops Screened - Reject";
      break;
    default:
      let dateString = null;
      const date = new Date(row.createdAt.seconds * 1000);
      dateString = date.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
      });
      if (row.test_completion) {
        status = "In Re-Assessment";
      } else {
        status = "Code Generated on " + dateString;
      }

      showCodeGenerated = true;
      break;
  }
  const name = row.firstName + " " + row.lastName;

  return (
    <Bill
      showCodeGenerated={showCodeGenerated}
      status={status}
      name={name}
      email={row.email}
      phone={row.phoneNumber}
      completion={row.test_completion}
      row={row}
    />
  );
};

const getLoader = () => {
  return (<RotatingLines
    strokeColor="black"
    strokeWidth="5"
    animationDuration="0.75"
    width="96"
    visible={true}
  />)
}
const CandidateList = () => {

  const [regions, setRegions] = useState([]);
  const [usersScreened, setUsersScreened] = useState([]);
  const [usersPassed, setUsersPassed] = useState([]);
  const [usersRejected, setUsersRejected] = useState([]);

  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [isBottom, setIsBottom] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isStatsLoading, setIsStatsLoading] = useState(false);

  const [endDoc, setEndDoc] = useState(null);
  const [page, setPage] = useState(1);


  const [selectedMonth, setSelectedMonth] = useState();

  const [selectedFilters, setSelectedFilters] = useState([
    "Rejected",
    "Passed",
    "Screened",
    "All",
  ]);
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });
  const [selectedEffectiveDayRange, setSelectedEffectiveDayRange] = useState({
    from: null,
    to: null,
  });
  const [selectedRegion, setSelectedRegion] = useState("");

  const [selectedModalRegion, setSelectedModalRegion] = useState("");
  const [stats, setStats] = useState({ screened: 0, passed: 0, rejected: 0 });

  const [filter, setFilter] = useState("");
  const history = useNavigate();

  const [open, setOpen] = useState(false);
  const [showDatePicker, setShowDatePicker] = React.useState(false);

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };


  const exportSelected = async () => {
    setIsStatsLoading(true);

    const combinedList = [...usersScreened, ...usersPassed, ...usersRejected];
    const resultsUserIds = [];
    const usersMap = {};
    const userArray = combinedList.map((user) => {
      if (user.resultStatus === "Passed" || user.resultStatus === "Screened"
        || user.resultStatus === "Rejected") {
        resultsUserIds.push(user.id);
      }

      usersMap[user.id] = user;

      return user;
    });

    const chunkedResultsUserIds = chunkArray(resultsUserIds, 29);

    const chunk1 = ['877618', '304436']
    const qx = query(
      collection(db, "assessments"),
      where("userId", "in", chunk1),
      orderBy("createdAt", "desc"),
      limit(20)
    );

    const assessmentSnapshot1 = await getDocs(qx);
    assessmentSnapshot1.docs.forEach((doc) => {
      const assessment = doc.data();
      assessment.id = doc.id;

      const userId = assessment.userId;
      if (usersMap[userId]) {
        if (!usersMap[userId].assessments) {
          usersMap[userId].assessments = [];
        }
      }
    });

    for (const chunk of chunkedResultsUserIds) {
      if (chunk.includes("808183")) {
        console.log("Yooo...", chunk)
      }
      console.log("Critical.. ", chunk)
      const q2 = query(
        collection(db, "assessments"),
        where("userId", "in", chunk),
        orderBy("createdAt", "desc"),
      );
      const assessmentSnapshot = await getDocs(q2);

      assessmentSnapshot.docs.forEach((doc) => {
        const assessment = doc.data();
        assessment.id = doc.id;
        const userId = assessment.userId;

        if (assessment.userId === '808183') {
          console.log("Stop Again...", userId, usersMap[userId], usersMap["808183"])
        }
        if (usersMap[userId]) {
          if (!usersMap[userId].assessments) {
            usersMap[userId].assessments = [];
          }
          usersMap[userId].assessments.push(assessment);
        }

      });
    }

    await exportToExcel(userArray)
    setIsStatsLoading(false);
  };

  const exportAll = async () => {
    setIsStatsLoading(true);
    const q1 = query(
      collection(db, "users"),
      where("userType", "==", "Candidate"),
      orderBy("createdAt", "desc")
    );

    try {
      const userSnapshot = await getDocs(q1);
      const resultsUserIds = [];
      const usersMap = {};
      const userArray = userSnapshot.docs.map((doc) => {
        const user = doc.data();
        user.id = doc.id;

        if (user.resultStatus === "Passed" || user.resultStatus === "Screened"
          || user.resultStatus === "Rejected") {
          resultsUserIds.push(user.id);
        }

        usersMap[user.id] = user;

        return user;
      });

      const chunkedResultsUserIds = chunkArray(resultsUserIds, 29);

      for (const chunk of chunkedResultsUserIds) {
        const q2 = query(
          collection(db, "assessments"),
          where("userId", "in", chunk),
          orderBy("createdAt", "desc"),
        );
        const assessmentSnapshot = await getDocs(q2);

        assessmentSnapshot.docs.forEach((doc) => {
          const assessment = doc.data();
          assessment.id = doc.id;

          const userId = assessment.userId;
          if (usersMap[userId]) {
            if (!usersMap[userId].assessments) {
              usersMap[userId].assessments = [];
            }
            usersMap[userId].assessments.push(assessment);
          }
        });
      }

      await exportToExcel(userArray)
      setIsStatsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsStatsLoading(false);
    }
  };


  // Assuming user and assessment data are already fetched and stored in variables userArray and assessmentArray

  // Function to calculate the average of three scores
  const calculateAverage = (score1, score2, score3) => {
    const total = Number(score1) + Number(score2) + Number(score3);
    const average = total / 3;
    return average.toFixed(2);
  };

  const formatDate = (timestamp) => {
    console.log('Is this called ??? ', timestamp)
    if (!timestamp) return "";
    const date = new Date(timestamp * 1000);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear() % 100;

    return `${month}/${day}/${year}`;
  };

  // Function to export data to Excel
  const exportToExcel = async (userArray) => {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Candidates");

    // Define the headers for the worksheet
    worksheet.columns = [
      { header: "Email", key: "email" },
      { header: "Full Name", key: "fullName" },
      { header: "Phone", key: "phone" },
      { header: "Region", key: "region" },
      { header: "date", key: "testCompletionDate" },
      { header: "Listening Score", key: "listeningScore" },
      { header: "Vocabulary Score", key: "vocabScore" },
      { header: "Reading Similarity Score", key: "readingSimilarity" },
      { header: "Avg Score", key: "avgScore" },
      { header: "Total Time", key: "totalTime" },
      { header: "Attempts", key: "attempts" },
      { header: "Results", key: "results" },
      { header: "Feedback", key: "feedback" },
    ];

    const dataToExport = userArray.map((user) => {
      const userId = user.id;
      const fullName = user.firstName + " " + user.lastName;
      const totalAttempts = user.assessments ? user.assessments.length : 0;


      const resultStatus = user.resultStatus
      let feedback;
      let assessment;

      let listeningScore = "";
      let vocabScore = "";
      let readingSimilarity = "";
      let scores = ""
      let testCompletionDate = "";
      let averageScore = ""
      let attempts = "";
      let totalTime = ""
      let result1 = ""
      if (resultStatus === 'Initial') {

      } else {
        if (user.email === 'maitabrady@yahoo.com') {
          console.log('HI')
        }
        result1 =
          user.resultStatus === "Screened" ? "Screened" : user.isPass ? "Passed" : "Rejected";

        if (user.assessments && user.assessments.length > 0) {
          assessment = user.assessments.find((a) => a.resultStatus);
          if (assessment) {
            feedback = assessment.feedback;
          }
        }
        testCompletionDate = formatDate(user.test_completion?.seconds);

        listeningScore = !isNaN(parseFloat(assessment?.listeningScore)) ? parseFloat(assessment?.listeningScore) : "";
        vocabScore = !isNaN(parseFloat(assessment?.vocabScore)) ? parseFloat(assessment?.vocabScore) : "";
        readingSimilarity = !isNaN(parseFloat(assessment?.reading_similarity)) ? parseFloat(assessment?.reading_similarity) : "";
        const score1 = (readingSimilarity + vocabScore + listeningScore);
        averageScore = (score1 / 3).toFixed(2);

        const totalSeconds = user.test_completion ? user.test_completion.seconds : 0;
        const totalMinutes = Math.floor(totalSeconds / 60);
        const totalHours = Math.floor(totalMinutes / 60);

        totalTime = assessment ? getTotalTime(assessment) : ''
        attempts = user.assessments ? user.assessments.length : 0;

      }

      const result = "N/A";
      worksheet.addRow({
        email: user.email,
        fullName: `${user.firstName} ${user.lastName}`,
        phone: user.phoneNumber,
        region: user.region,
        testCompletionDate: testCompletionDate,
        listeningScore: listeningScore,
        vocabScore: vocabScore,
        readingSimilarity: readingSimilarity,
        avgScore: averageScore,
        totalTime: totalTime,
        attempts: attempts,
        results: result1,
        feedback: feedback,
      });

      return {
        email: user.email,
        fullName,
        phone: user.phoneNumber,
        region: user.region,
        testCompletionDate: testCompletionDate,
        listeningScore,
        vocabScore,
        readingSimilarity,
        averageScore,
        totalTime,
        totalAttempts,
        resultStatus: result1,
        feedback,
      };


    });
    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a Blob from the buffer
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    // Save the Blob as a file
    const fileName = "candidates.xlsx";
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    // Now you can use dataToExport to export to Excel or perform any other operation as needed
    console.log(dataToExport);
  };


  // if (isLoading) return (getLoader()) 

  const getModalDiv = () => {
    return (
      <div>
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>
            Statistics
            <CloseIcon
              style={{ float: "right", cursor: "pointer" }}
              onClick={handleClose}
            />
          </DialogTitle>
          <DialogContent
            style={{
              width: "80vh%",
              height: "70vh",
            }}
          >
            {getModalFilter()}
            <SoftBox py={3}>
              <SoftBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} xl={4}>
                    {isStatsLoading ? (
                      <CircularProgress color="gt_primary" />
                    ) : (
                      <MiniStatisticsCard
                        title={{ text: "Screened" }}
                        count={stats.screened}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4} xl={4}>
                    {isStatsLoading ? (
                      <CircularProgress color="gt_primary" />
                    ) : (
                      <MiniStatisticsCard
                        title={{ text: "Passed" }}
                        count={stats.passed}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4} xl={4}>
                    {isStatsLoading ? (
                      <CircularProgress color="gt_primary" />
                    ) : (
                      <MiniStatisticsCard
                        title={{ text: "Rejected" }}
                        count={stats.rejected}
                      />
                    )}
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </DialogContent>
          {isStatsLoading ? (
            <DialogActions>
              <CircularProgress />
            </DialogActions>
          ) : (
            <>
              <DialogActions>
                <SoftButton onClick={exportSelected} color="gt_primary">
                  Export
                </SoftButton>
                <SoftButton onClick={exportAll} color="gt_primary">
                  Export All
                </SoftButton>

                <SoftButton onClick={handleClose} color="gt_primary">
                  Close
                </SoftButton>
              </DialogActions>
            </>
          )}
        </Dialog>
      </div>
    );
  };

  async function searchUsers(searchQuery) {
    const st = searchQuery + "\uf8ff";
    setUsers([]);

    setIsLoading(true);

    const firstNameQuery = query(
      collection(db, "users"),
      where("userType", "==", "Candidate"),
      where("firstNameSearch", ">=", searchQuery.toLowerCase()),
      where("firstNameSearch", "<=", st.toLowerCase()),
      orderBy("firstNameSearch", "desc"),
      orderBy("createdAt", "desc"),
      limit(pageSize)
    );

    const lastNameQuery = query(
      collection(db, "users"),
      where("userType", "==", "Candidate"),
      where("lastNameSearch", ">=", searchQuery.toLowerCase()),
      where("lastNameSearch", "<=", st.toLowerCase()),
      orderBy("lastNameSearch", "desc"),
      orderBy("createdAt", "desc"),
      limit(pageSize)
    );

    const phoneNumberQuery = query(
      collection(db, "users"),
      where("userType", "==", "Candidate"),
      where("phoneNumber", ">=", searchQuery),
      where("phoneNumber", "<=", st),
      orderBy("phoneNumber", "desc"),
      orderBy("createdAt", "desc"),
      limit(pageSize)
    );

    const emailQuery = query(
      collection(db, "users"),
      where("userType", "==", "Candidate"),
      where("email", ">=", searchQuery),
      where("email", "<=", st),
      orderBy("email", "desc"),
      orderBy("createdAt", "desc"),
      limit(pageSize)
    );

    const [
      firstNameResults,
      lastNameResults,
      phoneNumberResults,
      emailResults,
    ] = await Promise.all([
      getDocs(firstNameQuery),
      getDocs(lastNameQuery),
      getDocs(phoneNumberQuery),
      getDocs(emailQuery),
    ]);

    const mergedResults = mergeQueryResults(
      firstNameResults,
      lastNameResults,
      phoneNumberResults,
      emailResults
    );

    function mergeQueryResults(...results) {
      const merged = [];
      let i = 0;

      while (i < pageSize && results.some((result) => result.docs[i])) {
        const docs = results.filter((result) => result.docs[i]);

        const doc = docs.reduce((prev, curr) => {
          const fieldPath = curr.query?._fieldPath?.segments[0];
          if (
            fieldPath &&
            curr.docs[i].data()[fieldPath] > prev.data()[fieldPath]
          ) {
            return curr.docs[i];
          }
          return prev;
        }, docs[0].docs[i]);

        merged.push(doc);
        i++;
      }
      return merged;
    }

    const userArray = mergedResults.map((doc) => {
      const user = doc.data();
      user.id = doc.id;
      return user;
    });
    setUsers((prevUsers) => [...prevUsers, ...userArray]);

    setIsLoading(false);
    setHasMore(false);
    return mergedResults;
  }

  const pageSize = 25;

  const loadData = useCallback(
    (filterValue, filterTypes1, region, endDoc, clearResults, range) => {

      let q = query(
        collection(db, "users"),
        where("userType", "==", "Candidate"),
      );

      if (filterTypes1.length !== 4) {
        q = query(q, where("resultStatus", "in", filterTypes1));
      }
      if (filterValue === "screened") {
        q = query(q, where("resultStatus", "in", filterTypes1));
        q = query(q, where("currentStep", "==", "Finish"));
      }
      if (region && region !== "All" && region !== "") {
        q = query(q, where("region", "==", region));
      }

      // if (range) {
      //   q = query(q, where("range_month", "==", parseInt(range)));
      // }

      if (filterValue === "not_screened") {
        q = query(q, where("currentStep", "!=", "Finish"));
        q = query(q, orderBy("currentStep", "desc"));

      }
      q = query(q, orderBy("createdAt", "desc"));

      if (endDoc) {
        q = query(q, startAfter(endDoc));
        setIsLoading(false);
      }

      q = query(q, limit(pageSize));

      if (clearResults) setUsers([]);
      getDocs(q).then((userSnapshot) => {
        const userArray = userSnapshot.docs.map((doc) => {
          const user = doc.data();
          user.id = doc.id;
          return user;
        });
        setUsers((prevUsers) => [...prevUsers, ...userArray]);
        if (userArray.length > 0) {
          const obj = userSnapshot.docs[userSnapshot.docs.length - 1];
          setEndDoc(obj);
          if (userArray.length < pageSize) {
            setHasMore(false)
          } else {
            setHasMore(true)
          }
        }
        setIsLoading(false);
      });
    },
    []
  );

  useEffect(() => {
    if (searchQuery) {
      searchUsers(searchQuery);
    }
  }, [searchQuery]);

  const prevPageRef = useRef(page);
  const prevFilterRef = useRef(filter);

  useEffect(() => {

    const regionRef = collection(db, "regions");
    const regionsData = [];
    getDocs(regionRef)
      .then(async (querySnapshot) => {
        querySnapshot.forEach((docSnapshot) => {
          console.log(docSnapshot.id, " => ", docSnapshot.data());
          regionsData.push(docSnapshot.data());
        });
        setRegions(regionsData);
      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      })

    // updateUserAssessment() // run this for db updates only
    const getCurrentMonthDates = () => {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
      const currentDay = currentDate.getDate();

      const obj = {
        from: {
          day: 1,
          month: firstDayOfMonth.getMonth() + 1,
          year: firstDayOfMonth.getFullYear(),
        },
        to: {
          day: currentDay,
          month: currentMonth + 1,
          year: currentYear,
        },
      };

      return obj;
    };

    const initialSelectedDayRange = getCurrentMonthDates();
    setSelectedDayRange(initialSelectedDayRange);
    setSelectedEffectiveDayRange(initialSelectedDayRange);
    const currentDate = new Date();

    const date = new Date(currentDate.getFullYear(), currentDate.getMonth());
    const month1 = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const yeardate = `${year}${month1}`;

    setSelectedMonth(yeardate);
  }, []);

  useEffect(() => {
    setIsStatsLoading(true)
    if (!selectedDayRange.from && !selectedDayRange.to) return;
    let q = query(
      collection(db, "users"),
      where("userType", "==", "Candidate"),
      orderBy("test_completion", "desc")
    );

    if (selectedModalRegion && selectedModalRegion !== "All") {
      q = query(q, where("region", "==", selectedModalRegion));
    }

    if (showDatePicker) {
      const sDate = new Date(Date.UTC(selectedDayRange.from.year, selectedDayRange.from.month - 1, selectedDayRange.from.day))
      sDate.setHours(0, 0, 0, 0);
      const eDate = new Date(Date.UTC(selectedDayRange.to.year, selectedDayRange.to.month - 1, selectedDayRange.to.day))
      eDate.setHours(23, 59, 59, 999);
      const startDate = Timestamp.fromDate(sDate);
      const endDate = Timestamp.fromDate(eDate);

      q = query(q, where("test_completion", ">", startDate));
      q = query(q, where("test_completion", "<", endDate));
    } else {
      q = query(q, where("range_month", "==", selectedMonth));
    }

    const q1 = query(q, where("resultStatus", "in", ["Screened"]));
    const q2 = query(q, where("resultStatus", "in", ["Passed"]));
    const q3 = query(q, where("resultStatus", "in", ["Rejected"]));



    Promise.all([getDocs(q1), getDocs(q2), getDocs(q3)]).then((values) => {
      const [screenedSnapshot, passedSnapshot, rejectedSnapshot] = values;

      const countScreened = screenedSnapshot.size;

      const userArrayScreened = screenedSnapshot.docs.map((doc) => {
        const user = doc.data();
        user.id = doc.id;
        return user;
      });
      setUsersScreened(userArrayScreened)

      const countPassed = passedSnapshot.size;
      const userArrayPassed = passedSnapshot.docs.map((doc) => {
        const user = doc.data();
        user.id = doc.id;
        return user;
      });
      setUsersPassed(userArrayPassed)
      const countRejected = rejectedSnapshot.size;
      const userArrayRejected = rejectedSnapshot.docs.map((doc) => {
        const user = doc.data();
        user.id = doc.id;
        return user;
      });
      setUsersRejected(userArrayRejected)
      setStats({
        screened: countScreened,
        passed: countPassed,
        rejected: countRejected,
      });
      setIsStatsLoading(false)
    });
  }, [selectedDayRange, selectedModalRegion, selectedMonth]);

  useEffect(() => {
    setIsLoading(true);
    let endDoc1 = endDoc;
    if (prevFilterRef.current !== filter) {
      endDoc1 = null;
    }
    loadData(
      filter,
      selectedFilters,
      selectedRegion,
      endDoc1,
      prevFilterRef.current !== filter
    );

    prevPageRef.current = page;
    prevFilterRef.current = filter;
  }, [page, filter]);

  const generateMonthOptions = () => {
    const currentDate = new Date();
    const options = [];

    for (let i = 0; i < 15; i++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i
      );
      const month = date.toLocaleString("default", { month: "short" });
      const month1 = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const label = `${month} - ${year}`;
      options.push(
        <MenuItem key={i} value={`${year}${month1}`}>
          {label}
        </MenuItem>
      );
    }
    return options;
  };

  const handleScreenedClick = async () => {
    setSearchQuery("");
    setFilter("screened");
  };

  const handleNotScreenedClick = async () => {
    setSearchQuery("");
    setFilter("not_screened");
  };

  const handleGenerateCodeClick = () => {
    history("/addCandidate");
  };



  const handleScroll = debounce(() => {
    // Check if already loading data or no more data to load
    if (isLoading || !hasMore) return;

    // Calculate whether the user has reached the bottom of the page
    const isBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
    if (isBottom) {
      setPage((prevPage) => prevPage + 1);
    }
  }, 200); // Adjust the debounce time (in milliseconds) as needed (e.g., 200ms).

  const handleMonthFilter = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);
  };

  const handleRegionChange = (event) => {
    const value = event.target.value;
    setSelectedModalRegion(value);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleFilterChange = (event, isChecked, isRegion) => {
    let updatedFilters = [...selectedFilters];

    const value = event.target.value;

    let region = null;
    if (isRegion) {
      setSelectedRegion(value);
      region = value;
    } else {
      if (updatedFilters.includes(value)) {
        updatedFilters = updatedFilters.filter((filter) => filter !== value);
      } else {
        updatedFilters.push(value);
      }

      setSelectedFilters(updatedFilters);
    }
    loadData(filter, updatedFilters, region, null, true);
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  function getMonthAbbreviation(month) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[month - 1];
  }

  function padNumber(number) {
    return number.toString().padStart(2, "0");
  }

  function getTwoDigitYear(year) {
    return year.toString().slice(-2);
  }

  const formatInputValue = () => {
    if (selectedDayRange.from && selectedDayRange.to) {
      const from = selectedDayRange.from;
      const to = selectedDayRange.to;
      const formattedDate = `${getMonthAbbreviation(from.month)}/${padNumber(
        from.day
      )}/${getTwoDigitYear(from.year)} - ${getMonthAbbreviation(
        to.month
      )}/${padNumber(to.day)}/${getTwoDigitYear(to.year)}
      `;

      return formattedDate;
    } else {
      return "Select Date Range";
    }
  };

  const getFilterTypes = (isModal) => {
    return (
      <SoftBox
        display="flex"
        // alignItems="center"
        flexDirection={isMobile ? "column" : "row"}
        sx={{ marginBottom: 1 }}
      >
        <SoftBox
          display="flex"
          flexDirection="row"
          style={{
            paddingRight: isMobile ? "0px" : "12px",
          }}

        >
          <FormControl
            size="small"
            sx={{
              marginBottom: isMobile ? "8px" : "0",
              width: isMobile ? "100%" : "250px",
            }}
          >
            <InputLabel

              sx={{
                bgcolor: 'snow',
              }}
              style={{
                fontSize: "1rem", color: "inherit",
                zIndex: 1,
                backgroundColor: "white !important",
                paddingRight: "5px",
                paddingLeft: "3px"
              }} >
              Region
            </InputLabel>

            <Select
              onChange={(event) => handleFilterChange(event, false, true)}
              style={{
                fontSize: "1rem",
                color: "inherit",
                paddingRight: "1px !important",
              }}
              sx={{
                "& .MuiSelect-select": {
                  minWidth: isMobile ? "100%" : "200px",
                },
              }}
            >
              <MenuItem value="All">All</MenuItem>
              {regions.map((regionData) => (
                <MenuItem key={regionData.region} value={regionData.region}>
                  {regionData.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </SoftBox>
        <SoftBox display="flex" flexDirection="row" sx={{ width: "100%", opacity: 0.9 }}>
          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            mr="2"
            style={{ marginLeft: "12px" }}
          >
            <FormControlLabel
              style={{
                display: "flex",
                alignItems: "center",
              }}
              control={
                <Checkbox
                  checked={selectedFilters.includes("Screened")}
                  onChange={handleFilterChange}
                  value="Screened"
                />
              }
              label={
                <Typography variant="h6" style={{ fontWeight: 400 }}>
                  Screened
                </Typography>
              }
            />
            <FormControlLabel
              style={{
                display: "flex",
                alignItems: "center",
              }}
              control={
                <Checkbox
                  checked={selectedFilters.includes("Passed")}
                  onChange={handleFilterChange}
                  value="Passed"
                />
              }
              label={
                <Typography
                  variant="h6"
                  style={{ fontWeight: 400, marginRight: "8px" }}
                >
                  Passed
                </Typography>
              }
              labelPlacement="end"
            />
            <FormControlLabel
              style={{
                display: "flex",
                alignItems: "center",
              }}
              control={
                <Checkbox
                  checked={selectedFilters.includes("Rejected")}
                  onChange={handleFilterChange}
                  value="Rejected"
                />
              }
              label={
                <Typography
                  variant="h6"
                  style={{ fontWeight: 400, marginRight: "8px" }}
                >
                  Rejected
                </Typography>
              }
            />
          </SoftBox>

          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="flex-end" // Aligns content to the right
            flexGrow="1"
            ml="auto"
            // mr="1"
            // pr="1"
            style={{ alignItems: "center" }}
          >
            <SoftTypography
              variant="button"
              fontWeight="bold"
              color="gt_primary"
              onClick={handleOpen}
              style={{ paddingTop: "0px", marginLeft: "auto", cursor: "pointer", fontSize: "0.9rem" }}
            >
              Stats
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    );
  };

  const getModalFilter = () => {
    return (
      <SoftBox
        display="flex"
        // alignItems="center"
        flexDirection={isMobile ? "column" : "row"}
        gap={isMobile ? "0px" : "8px"}
        sx={{ marginBottom: 1 }}
        style={{ width: "100%", paddingTop: "5px" }}
      >
        <SoftBox display="flex" flexDirection="row">
          <FormControl
            size="small"
            sx={{
              marginBottom: isMobile ? "8px" : "0",
              width: isMobile ? "100%" : "200px",
              minWidth: isMobile ? "100%" : "200px",
            }}>
            <InputLabel
              sx={{
                bgcolor: 'snow',
              }}
              style={{
                fontSize: "1rem", color: "inherit",
                zIndex: 1,
                backgroundColor: "white !important",
                paddingRight: "5px",
                paddingLeft: "3px"
              }}>
              Region
            </InputLabel>

            <Select
              onChange={(event) => handleRegionChange(event)}
              value={selectedModalRegion}
              style={{
                fontSize: "1rem",
                color: "inherit",
                paddingRight: "1px !important",
              }}
              sx={{
                "& .MuiSelect-select": {
                  minWidth: isMobile ? "100%" : "150px",
                },
              }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="IND">India</MenuItem>
              <MenuItem value="DR">Dominican Republic</MenuItem>
              <MenuItem value="PHIL">Phillippines</MenuItem>
            </Select>
          </FormControl>
        </SoftBox>
        <SoftBox
          display="flex"
          flexDirection="row"
          sx={{ marginBottom: 1 }}
        // style={{ paddingRight: "16px" }}
        >
          {showDatePicker ? (
            <div
              style={{
                fontSize: "1rem !important",
                width: isMobile ? "100%" : "200px !important",
                minWidth: isMobile ? "100%" : "200px !important",
              }}
            >
              <DatePicker
                id="customDatePicker"
                value={selectedEffectiveDayRange}
                onChange={(e) => {
                  setSelectedEffectiveDayRange(e);
                  if (e.from !== null && e.to !== null) setSelectedDayRange(e);
                }}
                formatInputText={formatInputValue}
                inputPlaceholder="Select Range"
                calendarClassName="responsive-calendar"
                colorPrimary="#371D66"
                colorPrimaryLight="#FF7300"
                shouldHighlightWeekends
              />
            </div>
          ) : (
            <FormControl
              size="small"
              sx={{
                marginBottom: isMobile ? "8px" : "0",
                width: isMobile ? "100%" : "200px",
                minWidth: isMobile ? "100%" : "200px",
              }}
            >
              <InputLabel
                sx={{
                  bgcolor: 'snow',
                }}
                style={{
                  fontSize: "1rem", color: "inherit", zIndex: 1,
                  backgroundColor: "white !important",
                  paddingRight: "5px",
                  paddingLeft: "3px"
                }}>
                Month
              </InputLabel>

              <Select
                onChange={handleMonthFilter}
                value={selectedMonth}
                style={{
                  fontSize: "1rem",
                  color: "inherit",
                  paddingRight: "1px !important",
                }}
                sx={{
                  "& .MuiSelect-select": {
                    minWidth: isMobile ? "100%" : "150px",
                  },
                }}
              >
                {generateMonthOptions()}
              </Select>
            </FormControl>
          )}
          <SwapHorizIcon
            style={{ float: "right", cursor: "pointer" }}
            onClick={toggleDatePicker}
          />
        </SoftBox>
      </SoftBox>
    );
  };
  const CardList = () => {
    return (
      <>
        <InfiniteScroll
          dataLength={users.length}
          // next={handleScroll}
          hasMore={hasMore}
          loader={getLoader()}
          endMessage={<h6></h6>}
          style={{ background: "white", overflow: "hidden" }}
        >
          <>
            <SoftBox px={0}>
              <SoftBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={0}
                m={0}
                mb={4}
              >
                {users.map((user) => {
                  return (
                    <Link
                      to={`/details/${user.id}`}
                      style={{ textDecoration: "none", marginBottom: "8px" }}
                    >
                      <CardLayout1 row={user} />
                    </Link>
                  );
                })}
              </SoftBox>
            </SoftBox>
          </>
          <div></div>
        </InfiniteScroll>
      </>
    );
  };

  const handleSearchQuery = (txt) => {
    setFilter("");
    setSearchQuery(txt);
  };
  if (isLoading) {
    return (

      <div className="spinner">
        {/* {getLoader()} */}
        <MySpinner />
      </div>
    );
  }

  return (
    <div>
      <Banner linkTxt="/candidates" showLogoutButton={true} />
      <Container>
        <SearchBar
          onSubmit={handleSearchQuery}
          onClearQuery={handleSearchQuery}
        />
        {(filter === "screened" || filter === "") && getFilterTypes()}
        {/* <FilterBar setSearchQuery={setSearchQuery} /> */}
        <div className="mobile">
          <CardList />

          <Container
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              width: "auto",
              // opacity: 0.8,
              marginRight: "1.5rem",
              color: "white",
              height: "3rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0 1rem",
              position: "fixed",
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 999,
            }}
          >
            <SoftBox
              sx={{
                display: "flex",
                width: "33.33%",
                height: "100%",
                margin: "0 0.25rem",
              }}
            >
              <SoftButton
                // color={"gt_primary"}
                color={filter === "screened" ? "gt_secondary" : "gt_primary"}
                onClick={handleScreenedClick}
                sx={{ width: "100%" }}
              >
                Screened
              </SoftButton>
            </SoftBox>
            <SoftBox
              sx={{
                display: "flex",
                width: "33.33%",
                height: "100%",
                margin: "0 0.25rem",
              }}
            >
              <SoftButton
                color={filter === "not_screened" ? "gt_secondary" : "gt_primary"}
                // color={"gt_primary"}
                // variant="text"
                // variant="gradient"
                onClick={handleNotScreenedClick}
                sx={{ width: "100%" }}
              >
                Not Screened
              </SoftButton>
            </SoftBox>
            <SoftBox
              sx={{
                display: "flex",
                width: "33.33%",
                height: "100%",
                margin: "0 0.25rem",
              }}
            >
              <SoftButton
                color="gt_primary"
                // variant="gradient"
                onClick={handleGenerateCodeClick}
                sx={{ width: "100%" }}
              >
                Add Candidate
              </SoftButton>
            </SoftBox>
          </Container>
        </div>
      </Container>
      {getModalDiv()}
    </div>
  );
};

export default CandidateList;
