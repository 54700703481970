import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { SoftUIControllerProvider } from "./context";
import { AuthProvider } from "./auth-context/auth.context";
import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: 'GTM-NC9MPBCZ'
}
let user = localStorage.getItem("user");
user = JSON.parse(user);
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <AuthProvider userData={user}>
        <App />
      </AuthProvider>
    </SoftUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);