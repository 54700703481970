import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

const QuestionDataTable = ({ questions, onEdit, onView, onDelete }) => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  // Define columns for the data grid
  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'question', headerName: 'Question', width: 250 },
    { field: 'answer1', headerName: 'Answer 1', width: 150 },
    { field: 'answer2', headerName: 'Answer 2', width: 150 },
    { field: 'answer3', headerName: 'Answer 3', width: 150 },
    { field: 'answer4', headerName: 'Answer 4', width: 150 },
    { field: 'correctAnswer', headerName: 'Correct Answer', width: 150 },
    { field: 'regions', headerName: 'Regions', width: 150 },
    { field: 'type', headerName: 'Type', width: 150 },
  ];

  // Define rows
  const rows = questions.map((question, index) => ({
    id: index + 1,
    question: question.question,
    answer1: question.answer1,
    answer2: question.answer2,
    answer3: question.answer3,
    answer4: question.answer4,
    correctAnswer: question.correctAnswer,
    regions: question.regions,
    type: question.type,
  }));

  // Handle row selection
  const handleRowSelection = (selection) => {
    setSelectedQuestion(selection.rowIds[0]);
  };

  // Create a dialog for actions (Edit, View, Delete)
  const [openDialog, setOpenDialog] = useState(false);
  const closeDialog = () => {
    setSelectedQuestion(null);
    setOpenDialog(false);
  };

  // Handle the actions
  const handleEdit = () => {
    onEdit(selectedQuestion);
    closeDialog();
  };

  const handleView = () => {
    onView(selectedQuestion);
    closeDialog();
  };

  const handleDelete = () => {
    onDelete(selectedQuestion);
    closeDialog();
  };

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        checkboxSelection
        onSelectionModelChange={handleRowSelection}
        components={{
          Toolbar: GridToolbar,
        }}
      />
      <Dialog open={openDialog} onClose={closeDialog}>
        <DialogTitle>Question Actions</DialogTitle>
        <DialogContent>
          <DialogContentText>Choose an action to perform on the selected question:</DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton edge="start" color="inherit" onClick={handleEdit}>
            <EditIcon />
          </IconButton>
          <IconButton edge="start" color="inherit" onClick={handleView}>
            <VisibilityIcon />
          </IconButton>
          <IconButton edge="start" color="inherit" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default QuestionDataTable;
