/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "./SoftBox";
import SoftTypography from "./SoftTypography";
import SoftInput from "./SoftInput";
import SoftButton from "./SoftButton";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
// Authentication layout components
import { auth, db, provider } from "../firebase";
// Images
import curved9 from "../../public/curved-images/curved-6.jpg";
import logo from "../../public/logo/GTLogo_highRes.png";
import { useAuth } from "../auth-context/auth.context";
import CoverLayout from "./CoverLayout";
import Separator from "./Separator";
import {
  getDoc,
  doc,
  collection,
  setDoc,
  getDocs,
  where,
  query,
  serverTimestamp,
} from "firebase/firestore/lite";
import logoGoogle from "../assets/images/small-logos/logo-google.svg";
import { GoogleImage } from "./StyledComps";
import { Divider } from "@mui/material";

// const ZENDESK_KEY = "28b612aa-e5c1-4cd8-b51b-0bb1f2edd76a";

function Login1() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [rememberMe, setRememberMe] = useState(true);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");

  const { setUser } = useAuth();
  const { user } = useAuth();
  const [isEmployeeLogin, setIsEmployeeLogin] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleCode = (candidateCode) => {
    getDoc(doc(db, "users", candidateCode)).then((doc) => {
      const data = doc.data();
      if (data) navigate(`/assessment/${candidateCode}`);
      else setError("Invalid candidate code");
      setIsLoading(false);
      // Redirect the user to the appropriate landing page based on their userType
    });
  };

  const setting = {
    color: {
      theme: "#000",
    },
    launcher: {
      chatLabel: {
        "en-US": "Need Help",
      },
    },
    contactForm: {
      fields: [
        { id: "description", prefill: { "*": "My pre-filled description" } },
      ],
    },
  };

  const handleIsEmployeeLogin = (value) => {
    setError("");
    setFormData({});
    setIsEmployeeLogin(value);
  };

  const handleFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const submitFormData = (e) => {
    e.preventDefault();
    if (formData.candidate_code) {
      handleCode(formData.candidate_code);
    } else {
     
      if (formData.email && formData.password) {
        setIsLoading(true);

        // Perform form submission logic
        signInWithEmailAndPassword(auth, formData.email, formData.password)
          .then((userCredential) => {
            const user = userCredential.user;
            // Get the user's userType from the Firestore "users" collection
            getDoc(doc(db, "users", user.uid)).then((doc) => {
              const data = doc.data();
              const userType = data.userType;

              const userData = {
                ...userCredential.user,
                token: user.stsTokenManager.accessToken,
              };

              setUser(userData);
              localStorage.setItem("user", userData);
              localStorage.setItem("loggedIn", true);
              setIsLoading(false);
              setProfile(userData);
              // Redirect the user to the appropriate landing page based on their userType
              if (userType === "Employee") {
                navigate("/candidates");
              } else if (userType === "Candidate") {
                navigate(`/assessment/${user.uid}`);
              }
            });
          })
          .catch((error) => {
            setIsLoading(false);
            setError("Invalid email or password");
            console.error(error);
          });
      } else {
        setError('Please provide credentials.');
      }

    }

    // AuthApi.Login(formData)
    //   .then((response) => {
    //     if (response.data.success) {
    //       return setProfile(response);
    //     } else {
    //       setError(response.data.msg);
    //     }
    //   })
    //   .catch((error) => {
    //     if (error.response) {
    //       return setError(error.response.data.msg);
    //     }
    //     return setError("There has been an error.");
    //   });
  };

  const generateUniqueId = () => {
    let uniqueId = "";
    const possibleChars = "0123456789";

    for (let i = 0; i < 12; i++) {
      uniqueId += possibleChars.charAt(
        Math.floor(Math.random() * possibleChars.length)
      );
    }

    return uniqueId;
  };

  const signInWithGoogle1 = async () => {
    // signInWithGoogle(auth)
    try {
      setIsLoading(true)
      const res = await signInWithPopup(auth, provider);
      // const result = auth.signInWithPopup(provider);
      // const user = result.user;

      const user = res.user;
      const email = user.email;

      // Check if user with given email already exists
      const usersRef = collection(db, "users");
      const q1 = where("email", "==", email);
      const querySnapshot1 = await getDocs(query(usersRef, q1));
      if (querySnapshot1.docs.length > 0) {
        const existingUser = querySnapshot1.docs[0];
        const uid = existingUser.id;
        navigate(`/assessment/${uid}`);
        return;
      } else {
        let uid = generateUniqueId();

        let querySnapshot = await getDocs(
          query(usersRef, where("uid", "==", uid))
        );
        while (!querySnapshot.empty) {
          uid = generateUniqueId();
          querySnapshot = await getDocs(
            query(usersRef, where("uid", "==", uid))
          );
        }

        const newUser = {
          firstName: user.displayName,
          firstNameSearch: user.displayName.toLowerCase(),
          lastName: "",
          phoneNumber: "",
          userType: "Candidate",
          uid,
          email,
          currentStep: "",
          createdAt: serverTimestamp(),
          lastUpdatedOn: serverTimestamp(),
        };
        const userDocRef = doc(usersRef, uid);

        setDoc(userDocRef, newUser)
          .then(async (docRef) => {
            navigate(`/assessment/${uid}`);
          })
          .catch((error) => {
            console.error("Error adding user: ", error);
            // setLoading(false);
          });
        setIsLoading(false)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRedirect = () => {
    return navigate("/candidates");
  };

  const setProfile = (response) => {
    const user = JSON.stringify(response);
    setUser(user);
    localStorage.setItem("user", user);
    return navigate("/candidates");
  };

  const getLoginControls = () => {
    if (isEmployeeLogin)
      return (
        <>
          <SoftBox component="form" role="form" onSubmit={submitFormData}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="gt_primary"
                >
                  Email
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="email"
                name="email"
                value={formData?.email}
                onChange={handleFormData}
                placeholder="Email"
              />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="gt_primary"
                >
                  Password
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="password"
                name="password"
                onChange={handleFormData}
                placeholder="Password"
                value={formData?.password}
              />
            </SoftBox>
            <SoftBox display="flex" alignItems="center">
              {/* <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="gt_primary"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                &nbsp;&nbsp;Remember me
              </SoftTypography> */}
            </SoftBox>
            <SoftBox mt={2} mb={2} textAlign="center">
              <h6
                style={{
                  fontSize: ".8em",
                  color: "red",
                  textAlign: "center",
                  fontWeight: 400,
                  transition: ".2s all",
                }}
              >
                {error}
              </h6>
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <SoftButton
                variant="contained"
                color="gt_primary"
                onClick={submitFormData}
                fullWidth
              >
                sign in
              </SoftButton>
              <SoftBox mt={0} mb={1} style={{ textAlign: "right" }}>
                <SoftTypography
                  component={Link}
                  to="#"
                  variant="button"
                  color="gt_primary"
                  fontWeight="medium"
                  // textGradient
                  onClick={() => handleIsEmployeeLogin(false)}
                >
                  Back
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </>
      );
    else
      return (
        <>
          {/* <SoftBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb={3}
          >
            <SoftButton
              variant="outlined"
              color="gt_primary"
              onClick={signInWithGoogle1}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderColor: "#EDF0F3",
                width: "185px",
              }}
            >
              <GoogleImage
                src={logoGoogle}
                alt="Google Signin"
                style={{ height: "50px", width: "70px", marginBottom: "10px" }}
              />
              Sign in with Google
            </SoftButton>
          </SoftBox> */}

          {/* <Separator /> */}
          <SoftBox component="form" role="form">
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  color="gt_primary"
                  fontWeight="bold"
                >
                  Code
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="text"
                name="candidate_code"
                value={formData?.code}
                onChange={handleFormData}
                placeholder="Code"
              />
            </SoftBox>
            <SoftBox mt={2} mb={2} textAlign="center">
              <h6
                style={{
                  fontSize: ".8em",
                  color: "red",
                  textAlign: "center",
                  fontWeight: 400,
                  transition: ".2s all",
                }}
              >
                {error}
              </h6>
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <SoftButton
                variant="contained"
                color="gt_primary"
                onClick={submitFormData}
                fullWidth
              >
                Take Test
              </SoftButton>
            </SoftBox>

            <Divider
              mt={3}
              thickness={2}
              opacity={1}
              style={{ opacity: "1", thickness: "2", marginTop: "3rem" }}
            />
            <SoftBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={2}
              mt={3}
            // style={{
            //   height: "90px", width: "160px"
            // }}
            >
              <SoftButton
                variant="contained"
                color="gt_primary"
                onClick={() => handleIsEmployeeLogin(true)}
                fullWidth
              >
                Employee Sign In
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </>
      );
  };

  useEffect(() => {
    const url = window.location.href;
    const hasCode = url.includes("?code=");

    // If Github API returns the code parameter
    if (hasCode) {
      const newUrl = url.split("?code=");
      window.history.pushState({}, null, newUrl[0]);
      // setIsLoading(true);

      const requestData = {
        code: newUrl[1],
      };

      // AuthApi.Authorize(requestData.code)
      //   .then(({ data }) => {
      //     if (data.user) {
      //       setUser(JSON.stringify(data.user));
      //       localStorage.setItem("user", JSON.stringify(data.user));
      //       handleRedirect();
      //     } else {
      //       setError("no user returned");
      //     }
      //   })
      //   .catch((error) => {
      //     setError(error.message);
      //   })
      //   .finally(() => setIsLoading(false));
    }
  }, []);

  // let msgCandidate = "Login with Google to take the assessment";
  let msgCandidate = "";
  const msgEmployee = "Glowtouch Employee Sign In";

  if (user && user.token) {
    msgCandidate = "";
  }

  return (
    <CoverLayout
      color="gt_primary"
      title="Welcome"
      description={isEmployeeLogin ? msgEmployee : msgCandidate}
      image={curved9}
      top="30px"
      style={{ textAlign: "center", paddingLeft: "1rem" }}
    >
      <div style={{ display: "none" }}> mk test 1</div>

      <SoftBox mb={3} display="flex" justifyContent="center">
        {/* Employee ? */}
      </SoftBox>

      {isLoading ? (
        <SoftBox display="flex" justifyContent="center">
          <RotatingLines
            strokeColor="black"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </SoftBox>
      ) : user && user.token ? (
        <div>
          <h3 style={{ textAlign: "center" }}>You are already signed in.</h3>
          <SoftBox mt={4} mb={1}>
            <SoftButton
              variant="outlined"
              color="gt_primary"
              fullWidth
              onClick={handleRedirect}
            >
              {`Let's go`}
            </SoftButton>
          </SoftBox>
        </div>
      ) : (
        getLoginControls()
      )}
    </CoverLayout>
  );
}

export default Login1;
