import { Button, MenuItem } from "@mui/material";
import styled from "styled-components";
import stringSimilarity from "string-similarity";
import DOMPurify from "dompurify";
import {
  collection,
  doc,
  getDocs,
  updateDoc,
  query,
} from "firebase/firestore/lite";
export const BEGIN_TEST = "BEGIN_TEST";
export const VOCAB_TEST = "Vocabulary";
export const READING_TEST = "reading";
export const LISTENING_TEST = "listening";
export const FINISH = "Finish";
export const REGION_GENERAL = "G";
export const REGION_INDIA = "IND";
export const REGION_DOMINIC_REPUBLICAN = "DR";
export const REGION_PHILLIPINES = "PHIL";
export const APT_TEST = "aptitude";
export const COMPREHENSIVE_TEST = "comprehensive";
export const EMOTIONAL_INTELLIGENCE_TEST = "emotional_intelligence";
export const CALL_CENTER_KNOWLEDGE_TEST = "call_center_knowledge";
export const CUSTOMER_SERVICE_TEST = "customer_service";
export const SALES_TEST = "sales"
export const SALES_RETENTION_TEST = "sales_retention"
export const ATTENTION_TO_DETAIL_TEST = "attention"
export const TECH_SUPPORT = "tech_support"

export const getSection = () => {
  return {
    'sections': [
        {'field': 'Vocabulary', 'score': 'vocabScore', 'order': '1', 'topic': 'Vocabulary', 'time': 'grammer_time'},
        {'field': 'aptitude', 'score': 'aptitude_score', 'order': '2', 'topic': 'aptitude', 'time': 'aptitude_time'},
        {'field': 'comprehensive', 'score': 'comprehensive_score', 'order': '3', 'topic': 'comprehensive', 'time': 'comprehensive_time'},
        {'field': 'emotional_intelligence', 'score': 'ei_score', 'order': '4', 'topic': 'emotional_intelligence', 'time': 'ei_time'},
        {'field': 'customer_service', 'score': 'cs_score', 'order': '5', 'topic': 'customer_service', 'time': 'cs_time'},
        {'field': 'sales', 'score': 'sales_score', 'order': '6', 'topic': 'sales', 'time': 'sales_time'},
        {'field': 'reading', 'score': 'reading_similarity', 'order': '7', 'topic': 'reading', 'time': 'recording_time'},
        {'field': 'listening', 'score': 'percentageScore', 'order': '8', 'topic': 'listening', 'time': 'listening_time'},
    ],
    'Description': 'Here goes lots of description for support',
    'Name': 'Support Specialist'
  }
}

export const getCurrentWeekYear = () => {
  const now = new Date();
  const year = now.getFullYear();
  const firstDayOfYear = new Date(year, 0, 1);
  const pastDaysOfYear = (now - firstDayOfYear) / 86400000; // milliseconds in a day
  const week = Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  return { week, year };
};

export const navbarIconButton = ({ typography: { size }, breakpoints }) => ({
  px: 0.75,

  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
    // display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

export function convertToHTML(passages) {
  if (!passages) {
    return "";
  }

  let html = "";
  passages.split("\n").forEach((passage) => {
    const formattedPassage = passage
      .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;")
      .trim();
    html += `<p>${formattedPassage}</p>`;
  });

  const sanitizedHTML = DOMPurify.sanitize(html); // sanitize the HTML string

  return sanitizedHTML;
}

export const StyledLink = styled.span`
  text-decoration: underline;
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: #371D66 !important;
    color: white;
  `}
`;

export const CardListWrapper = styled.div`
  height: 80vh;
  overflow-y: scroll;
`;

export const AudioWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  marginBottom: "16px",
  textAlign: "left",
  "& p": {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    width: "100%",
  },
  "& audio": {
    flexGrow: 1,
    marginRight: "16px",
    width: "100%",
    // "&::-webkit-media-controls-enclosure": {
    //   display: "none",
    // },
    // "&::-webkit-media-controls-pause-button": {
    //   display: "none !important",
    // },
  },

  "& button": {
    flexGrow: 1,
    marginRight: "16px",
  },
  "@media (min-width: 768px)": {
    alignItems: "center",
    "& button": {
      maxWidth: "50%",
      width: "auto",
    },
    "& audio": {
      width: "99%",
    },
  },
});

export const QuestionContainer = styled.div`
  text-align: left;
  font-size: 1rem;
  border-top: 1px solid #ccc;
  & ul {
    list-style: none;
    padding: 0;
  }
`;

export const QuestionTitle = styled.h3`
  margin-bottom: 1rem;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
`;

export const QuestionHeader = styled.h2`
  margin-bottom: 1rem;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
`;

export const transcribeAudio = async (assessment) => {
  const formData = new FormData();


  // const storageRef = ref(getStorage());
  // const audioRef = ref(storageRef, `audios/${assessment.audioId}`);

  // const downloadURL = await fileRef.getDownloadURL();

  const response = await fetch(assessment.audioUrl);
  const blob = await response.blob();

  formData.append(
    "audio",
    blob
  );

  const fetchPromise = fetch(process.env.REACT_APP_SERVER_URL + "/transcribe", {
    method: "POST",
    body: formData,
  });

  const [fetchResult] = await Promise.all([fetchPromise]);

  const result = await fetchResult.text();
  const similarity =
    stringSimilarity.compareTwoStrings(result, assessment.passage) * 100;

    assessment.similarity = similarity
    assessment.transcribe = result
  // await updateDoc(doc(collection(db, "assessments"), assessment.uid), {
  //   reading_similarity: similarity.toFixed(2),
  //   transcribe: result,
  // });

  
};

