import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// const admin = require('firebase-admin');


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const secondApp = initializeApp(firebaseConfig, 'Secondary');

const db = getFirestore(app);

const usersCol = collection(db, 'users');
const auth = getAuth(app);
const secondAuth = getAuth(secondApp);
const provider = new GoogleAuthProvider(); 
// const adminAuth = getAuth(adminApp)
const storage = getStorage(app); // get a reference to Firebase Storage
// export { app, db, usersCol, auth, adminAuth };

export { app, db, usersCol, auth, secondAuth, storage, provider }