import React, { useState } from "react";
import { db } from "../firebase";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Container,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import styled from "styled-components";
import {
  collection,
  setDoc,
  doc,
  getDocs,
  where,
  query,
  serverTimestamp,
} from "firebase/firestore/lite";
import { useNavigate } from "react-router-dom";
import Banner from "./Banner";
import MySpinner from "./MySpinner";
import https from "https";
import SoftBox from "./SoftBox";
import SoftTypography from "./SoftTypography";
import SoftInput from "./SoftInput";
import SoftButton from "./SoftButton";
import { useMediaQuery } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

export default function AddCandidate() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const history = useNavigate();
  const handleCancel = (e) => {
    history("/candidates");
  };

  const handleFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const generateUniqueId = () => {
    let uniqueId = "";
    const possibleChars = "0123456789";

    for (let i = 0; i < 6; i++) {
      uniqueId += possibleChars.charAt(
        Math.floor(Math.random() * possibleChars.length)
      );
    }

    return uniqueId;
  };

  const validateForm = () => {
    if (!formData.firstName.trim()) {
      setError("First Name is required.");
      return false;
    }
    if (!formData.lastName.trim()) {
      setError("Last Name is required.");
      return false;
    }
    if (!formData.email.trim()) {
      setError("Email is required.");
      return false;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      setError("Invalid Email format.");
      return false;
    }
    if (!formData.phone.trim()) {
      setError("Phone is required.");
      return false;
    }

    if (!/^\+?\d{8,15}$/.test(formData.phone)) {
      setError("Phone must have between 8 and 15 digits");
      return false;
    }
    if (!formData.region || !formData.region.trim()) {
      setError("Region is required.");
      return false;
    }
    setError("");
    return true;
  };


  const handleSubmit = async (e) => {
    // e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    let uid = generateUniqueId();

    const usersRef = collection(db, "users");
    let querySnapshot = await getDocs(query(usersRef, where("uid", "==", uid)));
    while (!querySnapshot.empty) {
      uid = generateUniqueId();
      querySnapshot = await getDocs(query(usersRef, where("uid", "==", uid)));
    }

    const newUser = {
      firstName: formData.firstName,
      firstNameSearch: formData.firstName.toLowerCase(),
      lastName: formData.lastName,
      lastNameSearch: formData.lastName.toLowerCase(),
      phoneNumber: formData.phone,
      userType: "Candidate",
      uid,
      email: formData.email,
      region: formData.region,
      currentStep: "",
      resultStatus: "Initial",
      createdAt: serverTimestamp(),
    };

    const userDocRef = doc(usersRef, uid);
    // Add the new user to the "users" collection in Firestore
    setDoc(userDocRef, newUser)
      .then(async (docRef) => {
        const formDataEmail = new FormData();
        formDataEmail.append("recipient_email", formData.email);
        formDataEmail.append("code", uid);
        formDataEmail.append(
          "recipient_name",
          formData.firstName + " " + formData.lastName
        );

        const response = await fetch(
          process.env.REACT_APP_SERVER_URL + "/send_template",
          {
            method: "POST",
            body: formDataEmail,
            agent: new https.Agent({
              rejectUnauthorized: false,
              requestCert: false,
              agent: false,
            }),
          }
        );
        await response.text();
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        });
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      })
      .catch((error) => {
        console.error("Error adding user: ", error);
        setError("error adding user");
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div className="spinner">
        <MySpinner />
      </div>
    );
  }

  return (
    <>
      <Banner showBackButton={true} />
      <Container>
        {success && (
          <SoftBox mt={2} mb={2} textAlign="center">
            <h6
              style={{
                fontSize: ".8em",
                color: "green",
                textAlign: "center",
                fontWeight: 400,
                transition: ".2s all",
              }}
            >
              Candidate added
            </h6>
          </SoftBox>
        )}

        <Card>
          <CardContent>
            <SoftBox pt={1} pb={2}>
              <SoftBox pt={3}>
                <SoftTypography variant="body2" fontWeight="medium">
                  Add Candidate
                </SoftTypography>
              </SoftBox>

              <SoftBox
                component="form"
                role="form"
                onSubmit={handleSubmit}
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <SoftBox
                  sx={{ width: "100%", mb: 2 }}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                >
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      First Name
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="text"
                    name="firstName"
                    value={formData?.firstName}
                    onChange={handleFormData}
                    placeholder="First Name"
                  />
                </SoftBox>
                <SoftBox
                  sx={{ width: "100%", mb: 2 }}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                >
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Last Name
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="text"
                    name="lastName"
                    onChange={handleFormData}
                    placeholder="Last Name"
                    value={formData?.lastName}
                  />
                </SoftBox>

                <SoftBox
                  sx={{ width: "100%", mb: 2 }}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                >
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Email
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="email"
                    name="email"
                    onChange={handleFormData}
                    placeholder="Email"
                    value={formData?.email}
                  />
                </SoftBox>

                <SoftBox
                  sx={{ width: "100%", mb: 2 }}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                >
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Phone
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="phone"
                    name="phone"
                    onChange={handleFormData}
                    placeholder="Phone"
                    value={formData?.phone}
                  />
                </SoftBox>

                <SoftBox
                  sx={{ width: "100%", mb: 2 }}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                >
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Region
                    </SoftTypography>
                  </SoftBox>
                  <FormControl
                    size="small"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Select
                      id="region"
                      name="region"
                      onChange={(event) => handleFormData(event)}
                      style={{
                        fontSize: "1rem",
                        color: "inherit",
                        paddingRight: "1px !important",
                      }}
                      sx={{
                        "& .MuiSelect-select": {
                          minWidth: "100%",
                        },
                      }}
                    >
                      <MenuItem value="IND">India</MenuItem>
                      <MenuItem value="DR">Dominican Republic</MenuItem>
                      <MenuItem value="PHIL">Phillippines</MenuItem>
                    </Select>
                  </FormControl>
                </SoftBox>

                <SoftBox mt={2} mb={2} textAlign="center">
                  <h6
                    style={{
                      fontSize: ".8em",
                      color: "red",
                      textAlign: "center",
                      fontWeight: 400,
                      transition: ".2s all",
                    }}
                  >
                    {error}
                  </h6>
                </SoftBox>
                <SoftBox mt={2} mb={1}>
                  <SoftButton
                    variant="contained"
                    color="gt_primary"
                    onClick={handleSubmit}
                    fullWidth
                  >
                    Add Candidate
                  </SoftButton>
                </SoftBox>

                <SoftBox mt={4} mb={1}>
                  <SoftButton onClick={handleCancel} fullWidth>
                    Cancel
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
