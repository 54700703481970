import React, { useState } from "react";
import styled, { css } from "styled-components";
import { FaSearch } from "react-icons/fa";
import { MdClear } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { isStringNullOrEmpty } from "./StyledComps";

const Container = styled.div`
  width:100%
  padding: 1rem;
  margin-top: 4px;
  margin-bottom: 10px;
  // margin-right: 15px;
`;

const SearchInputs = styled.div`
  ${({ theme }) => css`
    display: flex;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    padding: 0.25rem;
    // width: 98%;
    // margin-left: 1rem;
    // margin-right: 1rem;
    padding-right: 0.5rem;
  `}
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;


const SearchInput = styled.input`
border: 0;
font-size: 1rem;
outline: none;
background-color: transparent;
text-overflow: ellipsis;
// max-width: 50rem;
// min-width: 42rem;
width: 100%;
`;
const Icon = styled.div`
height: 2rem;
width: 2rem;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
`;

function SearchBar({ value = "", placeholder, onSubmit, onClearQuery }) {
  const [input, setInput] = useState(value);
  const history = useNavigate();

  const handleInput = (e) => {
    setInput(e.target.value);
  };

  const handleClear = (e) => {
    if(onClearQuery) {
      onClearQuery(null)
    }
    setInput(e.target.value);
  };


  //trigger handleSubmit only when enter button is pressed within search input field or search icon is clicked
  const handleKeyPress = (e) => {
    if (e.key === "Enter" || e.currentTarget.title === "search") {
      input ? handleSubmit() : alert("Please enter search term to get results");
    }
  };

  const handleSubmit = () => {
    onSubmit(input);
  };

  return (
    <Container id={"SearchBar"}>
      <SearchInputs>
        <SearchInput
          type="text"
          value={input || ""}
          placeholder={placeholder}
          onChange={handleInput}
          onKeyPress={handleKeyPress}
        />
        {input && (
          <IconWrapper>
            <Icon onClick={handleClear}>
              <MdClear />
            </Icon>
          </IconWrapper>
        )}
        
        <IconWrapper>
          <Icon title={"search"} onClick={handleKeyPress}>
            <FaSearch />
          </Icon>
        </IconWrapper>
      </SearchInputs>
    </Container>
  );
}

export default SearchBar;
