import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
// import repairsMp3 from './listening/repairs.mp3';
import repairsMp3 from '../../public/listening/repairs.mp3';

const ListeningUploader = () => {
  const [questionsFile, setQuestionsFile] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [audioFiles, setAudioFiles] = useState([]);

  async function loadAudio(audioName, jsonName) {
    const audio = await import(`../../public/listening/${audioName}`);
    const json = await import(`../../public/listening/${jsonName}`);
    console.log(json);
    console.log('audio is ', audio)
    audioFiles.push({'audio_file': audio.default, 'json_file': json} )
  }

  useEffect(() => {
    const list = ['room', 'repairs']
      list.map(item => {
        loadAudio(item+'.mp3', item+'.json')
        return item
      })
      console.log('audioFile -- ', audioFiles)
  }, []);

  const handleFileChange = (event) => {
    setQuestionsFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!questionsFile) {
      setUploadError('Please select a file to upload.');
      return;
    }

    Papa.parse(questionsFile, {
      header: true,
      complete: async (results) => {
        const questions = results.data.map((row) => {
          const options = [];
          for (let i = 1; i <= Object.keys(row).length - 2; i++) {
            if (row[`option${i}`]) {
              options.push(row[`option${i}`]);
            }
          }
          const obj = {
            question: row.question,
            options: options,
            correct_answer: row.correct_answer
          };
          return obj;
        });
        const jsonData = JSON.stringify(questions);
        const blob = new Blob([jsonData], { type: 'application/json' });
        saveAs(blob, 'questions.json', { type: 'application/json;charset=utf-8' });
      },
    });
  };

  return (
    <div>
            {audioFiles.map((audio, index) => (
        <audio key={index} controls>
          <source src={audio.audio_file} type="audio/mp3" />
        </audio>
      ))}
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
      {uploadError && <div>{uploadError}</div>}
    </div>
  );
};

export default ListeningUploader;
