import React, { useState, useRef, useEffect } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../firebase";
import { v4 as uuidv4 } from "uuid";
import {
  Container,
  Card,
  CardContent,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import stringSimilarity from "string-similarity";
import {
  collection,
  doc,
  getDocs,
  where,
  updateDoc,
  query,
  serverTimestamp
} from "firebase/firestore/lite";
import MySpinner from "./MySpinner";
import { AudioWrapper, FINISH, LISTENING_TEST, convertToHTML } from "./helper";
import https from "https";
import SoftBox from "./SoftBox";
import SoftTypography from "./SoftTypography";
import SoftButton from "./SoftButton";
import adapter from "webrtc-adapter";
import TagManager from "react-gtm-module";

const isIPhone =
  adapter.browserDetails.browser === "safari" &&
  adapter.browserDetails.version < 14;

const useStyles = makeStyles((theme) => ({
  root: {
    animation: "$blink 2s linear infinite",
  },
  "@keyframes blink": {
    "0%": {
      opacity: 0.5,
    },
    "50%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0.5,
    },
  },
}));

const AudioRecorder1 = ({ assessment, handleNextStep, region, passage, testName, nextSectionName }) => {
  const [passage1, setPassage1] = useState("");
  const [passage2, setPassage2] = useState("");
  const [passage3, setPassage3] = useState("");

  const [permission, setPermission] = useState(true);
  const [stream, setStream] = useState(null);
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [audioChunks, setAudioChunks] = useState([]);
  const [audio, setAudio] = useState(null);
  const [audioBlob, setAudioBlob] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "component_loaded",
        component: "RecordingAccessment",
        passage: passage
      },
    });
  }, []);

  useEffect(() => {
    // setLoading(true);
    getMicrophonePermission();
    setLoading(false)
    // const q = query(
    //   collection(db, "reading_passages"),
    //   where("region", "==", region)
    // );
    // getDocs(q)
    //   .then((docSnapshot) => {
    //     const docArray = docSnapshot.docs.map((doc) => doc.data());
    //     const randomIndex = Math.floor(Math.random() * docArray.length);
    //     setPassage1(docArray[randomIndex].passage1);
    //     setPassage2(docArray[randomIndex].passage2);
    //     setPassage3(docArray[randomIndex].passage3);
    //     setLoading(false);
    //     getMicrophonePermission();
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  }, []);

  const getMicrophonePermission = async () => {
    try {
      if ("MediaRecorder" in window) {
        const constraints = isIPhone
          ? { audio: true }
          : { audio: true, video: false };
        const streamData = await navigator.mediaDevices.getUserMedia(constraints);
        setPermission(true);
        setStream(streamData);
      } else {
        alert("The MediaRecorder API is not supported in your browser.");
      }
    } catch (err) {
      // iPhone-specific instructions
      if (isIPhone) {
        alert(
          err.message +
            "\n " +
            "Go to the general Settings on your device." +
            "\n" +
            "Find and select Safari." +
            "\n " +
            "Scroll down to Setting for All Websites, and select Camera or Microphone." +
            "\n " +
            "Set Camera Access on All Websites or Microphone Access on All Websites to Allow." +
            "\n " +
            "Refresh the page."
        );
      } else {
        // Generic alert for non-iPhone devices
        alert("Microphone access is required. Please allow access in your browser settings.");
      }
      setPermission(false); // Explicitly set permission to false if denied
    }
  };
    
  const mimeType = "audio/mp4";
  // const mimeType = isIPhone ? "audio/mp4" : "audio/webm";
  const startRecording = async () => {
    if (!permission) {
      // Attempt to get permission again
      await getMicrophonePermission();
      return; // Exit function until permission is granted
    }
  
    try {
      setRecordingStatus("recording");
      const media = new MediaRecorder(stream, { type: mimeType });
      mediaRecorder.current = media;
      mediaRecorder.current.start();
  
      let localAudioChunks = [];
      mediaRecorder.current.ondataavailable = (event) => {
        if (typeof event.data === "undefined") return;
        if (event.data.size === 0) return;
        localAudioChunks.push(event.data);
      };
      setAudioChunks(localAudioChunks);
    } catch (err) {
      console.error("Error during recording:", err);
    }
  };

  const stopRecording = () => {
    setRecordingStatus("inactive");
    //stops the recording instance
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      //creates a blob file from the audiochunks data
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      setAudioBlob(audioBlob);
      //creates a playable URL from the blob file.
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudio(audioUrl);
      setAudioChunks([]);
    };
  };

  const submitAudio = async () => {
    
    setLoading(true);
    try {
      if (audio) {
        const formData = new FormData();
        formData.append("audio", await fetch(audio).then((r) => r.blob()));
        formData.append("assessment_uid", assessment.uid);
        formData.append("passage", passage);
        formData.append("user_id", assessment.userId);
        formData.append("nextStep", nextSectionName);
    
        // Make the fetch call
        const response = await fetch(process.env.REACT_APP_SERVER_URL + "/update_transcribe", {
          method: "POST",
          body: formData,
          agent: new https.Agent({
            rejectUnauthorized: false,
            requestCert: false,
            agent: false,
          }),
        });
  
        // Check if the response is OK (status 200-299)
        if (response.ok) {
          const data = await response.json();
          console.log("API Response:", data);
          handleNextStep();

        } else {
          // Handle the case where the response is not successful
          console.error("Failed to submit audio:", response.statusText);
        }
  
      } else {
        console.error("No audio file selected.");
      }
    } catch (error) {
      // Catch any errors during the process
      console.error("Error occurred while submitting audio:", error);
    } finally {
      // Make sure to stop the loading indicator in both success and error cases
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div>
        <MySpinner />
      </div>
    );
  }

  return (
    <Container>
      <SoftBox mt={1} mb={2}>
      <SoftTypography variant="body1" fontWeight="bold" color="dark">
              <div style={{display:"flex"}}>
                <p style={{flex:"0.5"}}>Reading</p>
                {/* <p style={{flex:"0.5", textAlign:"end"}}>{testName}</p> */}
              </div>
            </SoftTypography>
      </SoftBox>
      <SoftBox mb={1}>
            <SoftTypography variant="body2" fontWeight="medium" color="dark">
              Record yourself reading the paragraph below
            </SoftTypography>
          </SoftBox>
      <SoftBox>
        {/* {permission && recordingStatus === "inactive" ? (
          <SoftBox mt={1} mb={2}>
            <SoftButton
              variant="contained"
              color="gt_primary"
              onClick={startRecording}
              fullWidth
            >
              {audio !== null ? "Re-Recording" : "Start Recording"}
            </SoftButton>
          </SoftBox>
        ) : null} */}
        {recordingStatus === "recording" ? (
          <SoftBox mt={1} mb={2}>
            <SoftButton
              variant="gradient"
              color="warning"
              onClick={stopRecording}
              fullWidth
              className={classes.root}
            >
              Stop Recording
            </SoftButton>
          </SoftBox>
        ) : null}
        <Card key={1} style={{ marginBottom: 20 }}>
          <CardContent>
            <SoftBox mt={2}>
              <SoftTypography variant="h6" fontWeight="medium" color="text">
                <div
                  dangerouslySetInnerHTML={{ __html: convertToHTML(passage) }}
                ></div>
              </SoftTypography>
            </SoftBox>
          </CardContent>
        </Card>
        {passage2 && (
          <Card key={2} style={{ marginBottom: 20 }}>
            <CardContent>
              <SoftBox mt={2}>
                <SoftTypography variant="h6" fontWeight="medium" color="text">
                  {passage2}
                </SoftTypography>
              </SoftBox>
            </CardContent>
          </Card>
        )}
        {passage3 && (
          <Card key={3} style={{ marginBottom: 20 }}>
            <CardContent>
              <SoftBox mt={2}>
                <SoftTypography variant="h6" fontWeight="medium" color="text">
                  {passage3}
                </SoftTypography>
              </SoftBox>
            </CardContent>
          </Card>
        )}
        {recordingStatus === "inactive" ? (
          <SoftBox mt={2} mb={1}>
            <SoftButton
              variant="contained"
              color="gt_primary"
              onClick={startRecording}
              disabled={!permission}
              fullWidth
            >
              {audio !== null ? "Re-Recording" : "Start Recording"}
            </SoftButton>
          </SoftBox>
        ) : null}
        {recordingStatus === "recording" ? (
          <SoftBox mt={2} mb={1}>
            <SoftButton
              variant="gradient"
              color="warning"
              onClick={stopRecording}
              fullWidth
              className={classes.root}
            >
              Stop Recording
            </SoftButton>
          </SoftBox>
        ) : null}

        {audio ? (
          <AudioWrapper>
            <audio controls src={audio}></audio>
          </AudioWrapper>
        ) : null}
        {audio ? (
        <SoftBox mt={1} mb={1}>
            <SoftTypography variant="body2" fontWeight="medium" color="dark">
              If you are happy with your recording click next
            </SoftTypography>
          </SoftBox>
          ) : null}
        <SoftBox mt={1} mb={2}>
          <SoftBox mt={2} mb={1}>
            <SoftButton
              variant="contained"
              color="gt_primary"
              onClick={submitAudio}
              fullWidth
              disabled={audio === null}
              sx={{
                "&.Mui-disabled": {
                  opacity: "0.6",
                },
              }}
            >
              {nextSectionName === "Finish" ? "Submit" : "Next"}
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Container>
  );
};

export default AudioRecorder1;
