import React, { useState, useEffect } from "react";
import { auth, db } from "../firebase";
import { makeStyles } from "@material-ui/core/styles";
import sign  from 'jwt-encode';
import {
  Card,
  CardContent,
  Container,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import styled from "styled-components";
import {
  collection,
  setDoc,
  doc,
  getDocs,
  where,
  query,
  serverTimestamp,
} from "firebase/firestore/lite";
import { useNavigate } from "react-router-dom";
import Banner from "./Banner";
import MySpinner from "./MySpinner";
import https from "https";
import SoftBox from "./SoftBox";
import SoftTypography from "./SoftTypography";
import SoftInput from "./SoftInput";
import SoftButton from "./SoftButton";
import { useMediaQuery } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

export default function AddCandidate() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [regions, setRegions] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    region: "",
    test: "",
  });
  const history = useNavigate();
  const handleCancel = (e) => {
    history("/candidates");
  };

  const handleFormData = (event) => {
    const { name, value } = event.target;
    // Reset the test value when the region is changed
    if (name === "region") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        test: "", // Reset the test value when region changes
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const generateUniqueId = () => {
    let uniqueId = "";
    const possibleChars = "0123456789";

    for (let i = 0; i < 12; i++) {
      uniqueId += possibleChars.charAt(
        Math.floor(Math.random() * possibleChars.length)
      );
    }

    return uniqueId;
  };

  const validateForm = () => {
    if (!formData.firstName.trim()) {
      setError("First Name is required.");
      return false;
    }
    if (!formData.lastName.trim()) {
      setError("Last Name is required.");
      return false;
    }
    if (!formData.email.trim()) {
      setError("Email is required.");
      return false;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      setError("Invalid Email format.");
      return false;
    }
    if (!formData.phone.trim()) {
      setError("Phone is required.");
      return false;
    }

    if (!/^\+?\d{8,15}$/.test(formData.phone)) {
      setError("Phone must have between 8 and 15 digits");
      return false;
    }
    if (!formData.region || !formData.region.trim()) {
      setError("Region is required.");
      return false;
    }
    if (!formData.test || !formData.test.trim()) {
      setError("Test Name is required.");
      return false;
    }
    setError("");
    return true;
  };

  const generateToken = () => {
    const userId = 1;
    const SECRET_KEY = String(process.env.JWT_SECRET_KEY);
    const payload = {
      user_id: userId,
      exp: Math.floor(Date.now() / 1000) + 30 * 24 * 60 * 60,
      iss: 'lightbird'
    };

    try {
      const token = sign(payload, SECRET_KEY, { algorithm: 'HS256' });
      return token;
    } catch (error) {
      console.error("Error generating token:", error);
      return null;
    }
  };

  useEffect(() => {
    generateToken(); // Call the function when component mounts
  }, []); // Empty dependency array ensures it only runs once on mount

  useEffect(() => {
    const regionsData = [];
    const regionRef = collection(db, "regions");
    getDocs(regionRef)
      .then((querySnapshot) => {
        querySnapshot.forEach((docSnapshot) => {
          regionsData.push(docSnapshot.data());
        });
        setRegions(regionsData);
        console.log("Fetched regions:", regionsData);
      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      });
  }, []);

  const handleSubmit = async (e) => {
    if (!validateForm()) return;

    setLoading(true);

    const newCandidate = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      region: formData.region,
      tests: [formData.test],
      createdBy: auth.currentUser.email
    };
    const jwtToken = generateToken()
    try {
      const response = await fetch(process.env.REACT_APP_SERVER_URL + "/add_candidates", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `${jwtToken}`,
        },
        body: JSON.stringify([newCandidate]), 
        agent: new https.Agent({
          rejectUnauthorized: false,
          requestCert: false,
          agent: false,
        }),
      });

      const resultList = await response.json();

      if (resultList && resultList.length > 0) {
        const { code, email, message } = resultList[0]; 

        if (code && message !== 'Existing user found.') {
          setSuccess(true);
        } else {
          setError(`Error for ${email}: ${message}`);
        }

        setTimeout(() => {
          setSuccess(false);
          setError(null);
        }, 5000);
      }

      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        region: "",
        test: ""
      });
    } catch (error) {
      console.error("Error submitting candidate:", error);
      setError("An unexpected error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="spinner">
        <MySpinner />
      </div>
    );
  }

  return (
    <>
      <Banner showBackButton={true} />
      <Container>
        {success && (
          <SoftBox mt={2} mb={2} textAlign="center">
            <h6
              style={{
                fontSize: ".8em",
                color: "green",
                textAlign: "center",
                fontWeight: 400,
                transition: ".2s all",
              }}
            >
              Candidate added
            </h6>
          </SoftBox>
        )}

        <Card>
          <CardContent>
            <SoftBox pt={1} pb={2}>
              <SoftBox pt={3}>
                <SoftTypography variant="body2" fontWeight="medium">
                  Add Candidate
                </SoftTypography>
              </SoftBox>

              <SoftBox
                component="form"
                role="form"
                onSubmit={handleSubmit}
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <SoftBox
                  sx={{ width: "100%", mb: 2 }}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                >
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      First Name
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="text"
                    name="firstName"
                    value={formData?.firstName}
                    onChange={handleFormData}
                    placeholder="First Name"
                  />
                </SoftBox>
                <SoftBox
                  sx={{ width: "100%", mb: 2 }}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                >
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Last Name
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="text"
                    name="lastName"
                    onChange={handleFormData}
                    placeholder="Last Name"
                    value={formData?.lastName}
                  />
                </SoftBox>

                <SoftBox
                  sx={{ width: "100%", mb: 2 }}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                >
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Email
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="email"
                    name="email"
                    onChange={handleFormData}
                    placeholder="Email"
                    value={formData?.email}
                  />
                </SoftBox>

                <SoftBox
                  sx={{ width: "100%", mb: 2 }}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                >
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Phone
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="phone"
                    name="phone"
                    onChange={handleFormData}
                    placeholder="Phone"
                    value={formData?.phone}
                  />
                </SoftBox>

                <SoftBox
                  sx={{ width: "100%", mb: 2 }}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                >
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Region
                    </SoftTypography>
                  </SoftBox>
                  <FormControl
                    size="small"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Select
                      id="region"
                      name="region" // Make sure name is set as 'region' to update formData correctly
                      value={formData.region} // Ensure the selected value is bound to formData.region
                      onChange={handleFormData} // Keep the original handleFormData for updating the state
                      style={{
                        fontSize: "1rem",
                        color: "inherit",
                        paddingRight: "1px !important",
                      }}
                      sx={{
                        "& .MuiSelect-select": {
                          minWidth: "100%",
                        },
                      }}
                    >
                      {/* Dynamically create MenuItems */}
                      {regions.map(
                        (regionObj, index) =>
                          regionObj.show_dropdown && (
                            <MenuItem key={index} value={regionObj.region}>
                              {regionObj.name}
                            </MenuItem>
                          )
                      )}
                    </Select>
                  </FormControl>
                </SoftBox>
                {formData.region && (
                  <SoftBox sx={{ width: "100%", mb: 2 }} xs={12} sm={6} md={6} lg={4} xl={4}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Test Name
                      </SoftTypography>
                    </SoftBox>

                    <FormControl size="small" sx={{ width: "100%" }}>
                      <Select
                        value={formData.test || ""} // Bind to formData.test (this ensures previous test value is cleared when region changes)
                        onChange={handleFormData} // Use handleFormData to update formData
                        name="test" // This is necessary for handleFormData to correctly update formData
                        sx={{
                          fontSize: "1rem", // Set the font size
                          color: "inherit", // Ensure the text color is inherited
                          paddingRight: "1px !important", // Add padding right
                          "& .MuiSelect-select": {
                            minWidth: "100%", // Set minWidth to 100% for full-width
                          },
                        }}
                      >
                        <MenuItem value="">Select Test</MenuItem>

                        {/* Access the selected region and render its tests */}
                        {regions
                          .find((region) => region.region === formData.region)
                          ?.tests && // Ensure tests exist for the selected region
                          Object.keys(
                            regions.find((region) => region.region === formData.region)?.tests
                          ).map((testKey) => {
                            const test = regions.find(
                              (region) => region.region === formData.region
                            )?.tests[testKey];

                            // Only show tests that are not deprecated
                            if (!test?.deprecated) {
                              return (
                                <MenuItem key={testKey} value={testKey}>
                                  {test?.Name} {/* Display the Name of the test */}
                                </MenuItem>
                              );
                            }

                            return null; // Skip the deprecated test
                          })}
                      </Select>
                    </FormControl>
                  </SoftBox>
                )}

                <SoftBox mt={2} mb={2} textAlign="center">
                  <h6
                    style={{
                      fontSize: ".8em",
                      color: "red",
                      textAlign: "center",
                      fontWeight: 400,
                      transition: ".2s all",
                    }}
                  >
                    {error}
                  </h6>
                </SoftBox>
                <SoftBox mt={2} mb={1}>
                  <SoftButton
                    variant="contained"
                    color="gt_primary"
                    onClick={handleSubmit}
                    fullWidth
                  >
                    Add Candidate
                  </SoftButton>
                </SoftBox>

                <SoftBox mt={4} mb={1}>
                  <SoftButton onClick={handleCancel} fullWidth>
                    Cancel
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
