import React, { useState, useEffect, useCallback } from "react";
import { db } from "../firebase";
import {
  collection,
  doc,
  getDocs,
  where,
  updateDoc,
  query,
  serverTimestamp
} from "firebase/firestore/lite";
import {
  Button,
  Typography,
  Container,
  Grid,
  Card,
  FormControlLabel,
  Radio,
  Checkbox,
  TextField
} from "@mui/material";
import MySpinner from "./MySpinner";
import SoftBox from "./SoftBox";
import SoftTypography from "./SoftTypography";
import SoftButton from "./SoftButton";
import TagManager from "react-gtm-module";
import {
  BEGIN_TEST,
  FINISH,
  LISTENING_TEST,
  READING_TEST,
  VOCAB_TEST,
  APT_TEST,
  COMPREHENSIVE_TEST,
  EMOTIONAL_INTELLIGENCE_TEST,
  CALL_CENTER_KNOWLEDGE_TEST,
  CUSTOMER_SERVICE_TEST,
  SALES_TEST,
  SALES_RETENTION_TEST,
  ATTENTION_TO_DETAIL_TEST,
  TECH_SUPPORT
} from "./helper";

export default function GrammerAssessment({ assessment, handleNextStep, region, questions, category, testName, nextSectionName }) {
  const [loadingVocab, setLoadingVocab] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [vocabAnswers, setVocabAnswers] = useState([]);
  const [submitScores, setSubmitScores] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (questions) {
      setSelectedAnswers(Array(questions.length).fill(""));
    }

  }, [questions]);

  const handleAnswer = (e, value, index) => {
    const newAnswers = [...selectedAnswers];
    newAnswers[index] = value;
    setSelectedAnswers(newAnswers);
  };

  const handleTextAnswer = (e, index) => {
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[index] = e.target.value;
    setSelectedAnswers(updatedAnswers);
  };
  const handleNextStep1 = useCallback(() => {
    handleNextStep();
  }, []);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "component_loaded",
        component: "GrammerAssessment",
        questions: questions
      },
    });
  }, []);

  useEffect(() => {
    const updateGrammarScores = async () => {
      if (!submitScores || isSubmitting || !assessment?.uid || !vocabAnswers?.length) {
        return;
      }

      setIsSubmitting(true);
      const requestBody = {
        assessment_uid: assessment.uid,
        answers: vocabAnswers,
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/update_grammar_scores`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        console.log("API Response Status:", response.status);

        if (response.ok) {
          const data = await response.json();
          console.log("API Call Success");
          handleNextStep1();
        } else {
          console.error("Failed to update grammar scores:", response.statusText);
        }
      } catch (error) {
        console.error("Error occurred during API call:", error);
      } finally {
        setIsSubmitting(false);
        setSubmitScores(false);
      }
    };

    updateGrammarScores();
  }, [submitScores, assessment, vocabAnswers, handleNextStep1]);


  const submit = () => {
    const newVocabAnswers = questions.map((question, index) => {
      const {
        question: questionText,
        correct_answer,
        difficulty,
        category,
      } = question;
      const answer = selectedAnswers[index];
      return {
        question: questionText,
        answer,
        difficulty,
        category,
        correct_answer,
      };
    });
    const newVocabAnswers1 = questions.map((question, index) => {
      const {
        question: questionText,
        correct_answer,
        difficulty,
        category,
      } = question;
      const answer = selectedAnswers[index];
      return answer
      // question: questionText,

      // difficulty,
      // category,
      // correct_answer,

    });


    setVocabAnswers(newVocabAnswers1);
    setSubmitScores(true);
  };

  const section_header =
    category === VOCAB_TEST ? "Vocabulary and Grammar" :
      category === APT_TEST ? "Apptitude w/Technical" :
        category === COMPREHENSIVE_TEST ? "Comprehension" :
          category === EMOTIONAL_INTELLIGENCE_TEST ? "Emotional Intelligence" :
            category === CALL_CENTER_KNOWLEDGE_TEST ? "Call Center Knowledge" :
              category === CUSTOMER_SERVICE_TEST ? "Customer Service" :
                category === TECH_SUPPORT ? "Technical Proficiency" :
                  category === SALES_TEST ? "Sales" :
                    category === SALES_RETENTION_TEST ? "Sales & Retention" :
                      category === ATTENTION_TO_DETAIL_TEST ? "Attention to Detail" :
                      [];

  const renderGrammer = () => {
    if (loadingVocab || !questions) {
      return (
        <div className="spinner">
          <MySpinner />
        </div>
      );
    } else {
      return (
        <>
          <SoftBox mt={1} mb={1}>
            <SoftTypography variant="body1" fontWeight="bold" color="dark">
              <div style={{display:"flex"}}>
                <p style={{flex:"0.5"}}>{section_header}</p>
                {/* <p style={{flex:"0.5", textAlign:"end"}}>{testName}</p> */}
              </div>
            </SoftTypography>
          </SoftBox>
          <SoftBox mt={1} mb={1}>
            <SoftTypography variant="body2" fontWeight="medium" color="dark">
              Choose the word that best completes the sentence
            </SoftTypography>
          </SoftBox>
          <SoftBox pt={1}>
            {questions.map((question, index) => (
              <Card
                key={index}
                mb={2}
                variant="outlined"
                style={{ marginBottom: "1rem", paddingBottom: "1rem" }}
              >
                <SoftBox lineHeight={0}>
                  <SoftBox mb={1} pl={1} bgColor="grey-100">
                    <SoftTypography
                      variant="h6"
                      fontWeight="medium"
                      color="dark"
                      style={{ paddingBottom: "8px" }}
                    >
                      {question.question}
                    </SoftTypography>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "5px",
                        height: "15px",
                        fontWeight: 200,
                        fontSize: "0.8rem",
                        marginTop: "2px",
                        marginBottom: "4px",
                        bgColor: "grey-100",
                      }}
                    >
                      Q {index + 1} of {questions.length}
                    </div>
                  </SoftBox>

                  {/* Render input field if the question is SCRAMBLED_TEXT */}
                  {question.type === "SCRAMBLED_TEXT" ? (
                    <SoftBox mt={1} px={2}>
                      <TextField
                        id={`answer-${index}`}
                        name={`answer-${index}`}
                        value={selectedAnswers[index] || ""}
                        onChange={(e) => handleTextAnswer(e, index)}
                        fullWidth
                        variant="outlined"
                        placeholder="Rearrange the sentence"
                      />
                    </SoftBox>
                  ) : (
                    <SoftBox
                      component="ul"
                      display="flex"
                      flexDirection="column"
                      p={0}
                      m={0}
                      mt={0.25}
                      pt={0.5}
                    >
                      {question.options.map((choice, choiceIndex) => (
                        <SoftBox
                          key={choiceIndex}
                          component="li"
                          display="flex"
                          alignItems="center"
                          borderRadius="lg"
                          p={0.25}
                          px={2.5}
                          mb={0}
                        >
                          <FormControlLabel
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            control={
                              question.type === "SINGLE_SELECT" ? (
                                <Radio
                                  id={`answer-${index}-${choiceIndex}`}
                                  name={`answer-${index}`}
                                  value={choice}
                                  checked={selectedAnswers[index] === choice}
                                  onChange={(e) => handleAnswer(e, choice, index)}
                                  sx={{
                                    "&.MuiRadio-root": {
                                      height: "18px",
                                      width: "18px",
                                    },
                                  }}
                                />
                              ) : (
                                <Checkbox
                                  id={`answer-${index}-${choiceIndex}`}
                                  name={`answer-${index}`}
                                  value={choice}
                                  checked={(selectedAnswers[index] || []).includes(choice)}
                                  onChange={(e) => handleMultiAnswer(e, choice, index)}
                                  sx={{
                                    "&.MuiCheckbox-root": {
                                      height: "18px",
                                      width: "18px",
                                    },
                                  }}
                                />
                              )
                            }
                            label={
                              <SoftTypography
                                variant="body2"
                                fontWeight="medium"
                                color="dark"
                                ml={0.5}
                              >
                                {choice}
                              </SoftTypography>
                            }
                          />
                        </SoftBox>
                      ))}
                    </SoftBox>
                  )}
                </SoftBox>
              </Card>
            ))}
          </SoftBox>


          <SoftBox mt={1} mb={2}>
            <SoftBox mt={2} mb={1}>
              <SoftTypography variant="body2" fontWeight="medium" color="dark">
                Please answer all questions above to continue
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox mt={1} mb={2}>
            <SoftBox mt={2} mb={1}>
              <SoftButton
                variant="contained"
                color="gt_primary"
                onClick={submit}
                fullWidth
                disabled={
                  questions.some((q, index) =>
                    q.type === "MULTI_SELECT"
                      ? !selectedAnswers[index] || selectedAnswers[index].length === 0
                      : !selectedAnswers[index]
                  )
                }
                sx={{
                  "&.Mui-disabled": {
                    opacity: "0.6",
                  },
                }}
              >
                {nextSectionName === "Finish" ? "Submit" : "Next"}
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </>
      );
    }
  };


  const handleMultiAnswer = (e, value, index) => {
    const newAnswers = [...selectedAnswers];
    newAnswers[index] = newAnswers[index] || [];
    if (e.target.checked) {
      newAnswers[index] = [...newAnswers[index], value];
    } else {
      newAnswers[index] = newAnswers[index].filter((c) => c !== value);
    }
    setSelectedAnswers(newAnswers);
  };


  return (
    <Container>
      {renderGrammer()}
    </Container>
  );
}
