import React from "react";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.5);
`;

const MySpinner = () => {
  return (
    <CenteredContainer>
      <CircularProgress color="gt_primary"/>
    </CenteredContainer>
  );
};

export default MySpinner;
