import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { DivFlexCenter } from "../globals/styles";
import DOMPurify from "dompurify";
import Button from '@mui/material/Button';

export const isStringNullOrEmpty = (str) =>
  str === null || str === undefined || str.trim() === "";

export const headers = [
  {
    key: "context1",
    name: "Document Name",
    width: "40%",
    visible: true,
    render: "text",
  },
  {
    key: "prediction_score",
    name: "Confidence Score %",
    width: "20%",
    visible: true,
    render: "text",
  },
  {
    key: "prediction",
    name: "Does this look right?",
    width: "20%",
    visible: true,
    render: "rate",
  },
  // { key: 'action', name: 'Action', width: '20%', visible: true, render: 'link'},
  { key: "id", name: "ID", width: "0%", visible: false, render: "text" },
];

export function convertToHTML(passages) {
  if (!passages) {
    return "";
  }

  let html = "";
  passages.split("\n").forEach((passage) => {
    const formattedPassage = passage
      .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;")
      .trim();
    html += `<p>${formattedPassage}</p>`;
  });

  const sanitizedHTML = DOMPurify.sanitize(html); // sanitize the HTML string

  return sanitizedHTML;
}

export const Tooltip = styled.span`
  position: absolute;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  background-color: #4d4d4d;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  transition: opacity 0.2s ease-in-out;

  /* Position the tooltip above the text */
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);

  /* Add a triangle pointer */
  &::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #4d4d4d transparent;
  }
`;

export const GoogleButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
`;

export const GoogleImage = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;

export const Buttons = styled.div`
  position: fixed;
  bottom: 0;
  margin-bottom: 1rem;
  height: 2rem;
  justify-content: space-between !important;
  width: 100%;
  display: flex;
  background-color: white;
  z-index: 999;
`;

export const Button2s = styled.div`
  position: fixed;
  bottom: 0;
  margin-bottom: 1rem;
  height: 2rem;
  max-width: 1270px;
  justify-content: space-between !important;
  // width: 100%;
  display: flex;
  background-color: white;
  z-index: 999;

  // @media (min-width: 1200px) {
  //   justify-content: space-between; /* distribute buttons evenly across the width */
  //   padding: 0 20px; /* add padding to the sides of the buttons */
  // }
  // @media (max-width: 767px) {
  //   flex-wrap: wrap; /* allow buttons to wrap to a new line */
  //   justify-content: center; /* center the buttons horizontally within the container */
  //   gap: 10px; /* add spacing between buttons */
  // }
`;

export const Buttons1 = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex; // use flexbox to create a flexible layout
  justify-content: center; // center the items horizontally
  align-items: center; // center the items vertically
  max-width: 1200px; // set a maximum width for the div
  margin: 0 auto; // center the div horizontally

  // // use media queries to change the style based on device width
  // @media (max-width: 768px) {
  //   flex-direction: column; // stack the items vertically on small screens
  //   gap: 10px; // add some space between the items
  // }
`;

export const StyledButton1 = styled(Button)`
  flex-basis: 0;
  flex-grow: 1;
  margin: 0 5px; /* add margin between buttons */
`;
export const StyledButton = styled.button`
  background-color: #0077cc;
  border-radius: 2rem;
  color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border-style: none;
`;

export const StyledButton2 = styled(Button)`
  position: relative;
  line-height: 2 !important;
  font-size: 1rem !important;
  font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Ubuntu",
    "Helvetica Neue", sans-serif !important;
  border-radius: 6px;
  font-size: 1rem;
  text-decoration: none;
  text-transform: none;
  font-weight: 600 !important;
  text-transform: none !important;
  letter-spacing: 0.05em !important;
  margin-top: 20px !important;
`;

export const Separator = styled.div`
  height: 2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.colors.secondary};
    padding: 0.5rem;
    color: ${theme.colors.primary};
    padding-top: 0.5px;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: space-between;
    background-color: ${theme.colors.secondary};
    padding: 1rem 0rem 1rem 1rem;
    width: 100%
    color: ${theme.colors.primary};
  `}
`;

export const Icon = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    padding: 0.25rem;
    margin-left: 1rem;
    color: ${theme.colors.secondary};
    cursor: pointer;

    &:hover {
      border-radius: 10px;
      padding: 0.25rem;
      border: 1px solid ${theme.colors.secondary};
    }
  `}
`;

export const IconLink = styled(Link)`
  ${({ theme }) => css`
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: ${theme.colors.primary};
  `}
`;

export const CustomerInfoLabel = styled.div`
  display: flex;
  min-width: 50%;
  font-size: 20px;
  font-weight: 600;
`;

export const CheckListComponent = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 20px;
  margin-left: 0.5rem;
  padding-left: 2.1rem;
`;

export const ResultsWrapper = styled.div`
  // display: flex;
  padding: 0rem 0rem 1rem 1rem;
  font-size: 0.9rem;
  min-width: 50%;
  overflow: auto;
  border-radius: 4px;
  // min-height: 60%;
  -webkit-scrollbar: {
    width: 0;
    height: 0;
  }
  -moz-scrollbar: {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-moz-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
  }

  ::-webkit-scrollbar-track {
    background-color: #c4c4c4;
  }
`;

export const Container1 = styled.div`
  display: flex;
  height: auto;
  max-height: 55vh;
  min-height: 45vh;
  // gap: 1rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  border: 1px solid #ccc;
  padding: 0rem;
  border-radius: 5px;
`;

export const TextHCTooltip1 = styled.div`
  display: block;
  ul {
    margin-left: -20px;
  }
  position: ${({ isExpanded }) => (isExpanded ? "fixed" : "relative")};
  top: ${({ isExpanded }) => (isExpanded ? "0" : "auto")};
  bottom: ${({ isExpanded }) => (isExpanded ? "0" : "auto")};
  left: ${({ isExpanded }) => (isExpanded ? "0" : "auto")};
  right: ${({ isExpanded }) => (isExpanded ? "0" : "auto")};
  min-width: ${({ isExpanded }) => (isExpanded ? "80%" : "49%")};
  height: ${({ isExpanded }) => (isExpanded ? "80%" : "auto")};
  padding: ${({ isExpanded }) => (isExpanded ? "50px" : "3px")};
  opacity: ${({ isExpanded }) => (isExpanded ? "0.95" : "1")};
  border-radius: 4px;
  color: black;
  text-align: left;
  overflow-y: ${({ isExpanded }) => (isExpanded ? "auto" : "auto")};
  background-color: #f4f4f4;
  z-index: 100;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const Loading = styled(DivFlexCenter)`
  height: 50vh;
  top: 25%;
  left: 50%;
`;

export const Pagination = styled(DivFlexCenter)`
  gap: 2rem;
  padding: 1rem;
  font-size: 0.8rem;
`;

export const ApplicationName = styled.h1`
  margin: 0;
  font-size: 1.5rem;
  margin-left: 1rem;
`;

export const UserRole = styled.div`
  margin-right: 2rem;
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 0.1rem;
`;

export const BottomContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  min-height: 40vh;
  max-height: 60vh;
  margin: 0px 11px;
`;

export const OneBelowAnother = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 30vh;
  max-height: 35vh;
  margin: 0px 11px;
  width: 97.5%;
`;

export const LeftComponent = styled.div`
  border: 1px solid #ccc;
  display: flex;
  // flex-direction: column;
  flex: 1;
  padding: 0rem 1rem 1rem 1rem;
  font-size: 0.9rem;
  // min-width: 50%;
  overflow: auto;
  border-radius: 4px;
`;

export const UserInfoWrapper = styled.div`
  margin-top: 1rem;
  font-size: 1rem
  padding-top: 1rem;
  & > div {
    margin-bottom: 10px;
  }
  & > div:first-child {
    margin-top: 0;
  }
  & > div:last-child {
    margin-bottom: 0;
  }
`;

export const RightComponent = styled.div`
  border-radius: 4px;
  border: 1px solid #ccc;
  flex: 1; /* Add this to make it grow and fill remaining space */
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 15px;
`;

export const CheckboxLabel = styled.label`
  margin-bottom: 10px;
`;

export const CheckboxInput = styled.input`
  margin-right: 10px;
`;
