import React, { useState, useEffect, useCallback } from "react";
import { db } from "../firebase";
import { collection, doc, updateDoc, serverTimestamp } from "firebase/firestore/lite";
import {
  Button,
  Container,
  Card,
  FormControlLabel,
  Radio,
} from "@mui/material";
import styled from "styled-components";
import { AudioWrapper, FINISH } from "./helper";
import SoftBox from "./SoftBox";
import SoftTypography from "./SoftTypography";
import SoftButton from "./SoftButton";
import TagManager from "react-gtm-module";

export default function ListeningAssessment({
  audioFiles,
  assessment,
  handleNextStep,
}) {
  const [flatAnswers, setFlatAnswers] = useState([]);

  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);

  const [showQuestions, setShowQuestions] = useState(false);


  const [listeningQuestions, setListeningQuestions] = useState([]);

  const [selectedAnswers, setSelectedAnswers] = useState(Array(10).fill(""));

  const [doPause, setDoPause] = useState(false);
  const [submitScores, setSubmitScores] = useState(false);
  const [isLandingPage, setIsLandingPage] = useState(false);
  const [playCount, setPlayCount] = useState(0);

  const handleAnswer = (e, value, index) => {
    const newAnswers = [...selectedAnswers];
    newAnswers[index] = value;
    setSelectedAnswers(newAnswers);
  };
  const showAudio = () => {
    setIsLandingPage(false);
  };

  const handlePause = (e) => {
    if (!doPause) e.target.play();
    setDoPause(false);
  };

  const handleAudioEnd = (e) => {
    setDoPause(true);
    e.target.pause();
    setPlayCount(playCount + 1);
  };

  const loadQuestions = audioIndex => {
    const questionsJson = audioFiles[audioIndex].json_file;
    setListeningQuestions(questionsJson);
    setSelectedAnswers(Array(questionsJson.length).fill(""));

    // setShowQuestions(true);
  };

  const getYearWeek = () => {

    const currentDate = new Date();

    const date = new Date(currentDate.getFullYear(), currentDate.getMonth());
    const month1 = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const yeardate = `${year}${month1}`;
    return yeardate
  }
  const calculatePercentageScore = useCallback(() => {
    let numCorrect = 0;
    let totalQuestions = 0;
    flatAnswers.forEach((answer) => {
      totalQuestions++;
      if (answer.answer === answer.correct_answer) {
        numCorrect++;
      }
    });

    let percentageScore =
      totalQuestions > 0 ? (numCorrect / totalQuestions) * 100 : 0;

    percentageScore = percentageScore.toFixed(2);

    if (submitScores) {
      updateDoc(doc(collection(db, "assessments"), assessment.uid), {
        currentStep: FINISH,
        resultStatus: "Screened",
        listeningAnswer: flatAnswers,
        listeningScore: percentageScore,
        listening_time: serverTimestamp(),
      })
        .then(() => {
          console.log("Updated audio URL successfully");
        })
        .catch((error) => {
          console.error("Error updating audio URL:", error);
        });

      updateDoc(doc(collection(db, "users"), assessment.userId), {
        currentStep: FINISH,
        range_month: getYearWeek(),
        resultStatus: "Screened",
        listeningScore: percentageScore,
        test_completion: serverTimestamp(),
      })
        .then(() => {
          console.log("Updated users status");
        })
        .catch((error) => {
          console.error("Error user Status:", error);
        });
      handleNextStep();
    }
    return percentageScore;
  }, [submitScores]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "component_loaded",
        component: "ListeningAccessment",
        audioFiles: audioFiles
      },
    });
  }, []);

  useEffect(() => {
    loadQuestions(0);
  }, [audioFiles]);

  useEffect(() => {
    calculatePercentageScore();
  }, [submitScores, calculatePercentageScore]);

  const handleAnswerSelect = (isFinish) => {
    const audioFile = audioFiles[currentAudioIndex].audio_file;
    const newAnswers = listeningQuestions.map((question1, index) => {
      const { question, correct_answer } = question1;
      const answer = selectedAnswers[index];
      return {
        question,
        answer,
        correct_answer,
        audioFile,
      };
    });

    setFlatAnswers([...flatAnswers, ...newAnswers]);
    setCurrentAudioIndex((prevIndex) => prevIndex + 1);
    setListeningQuestions([]);
    setShowQuestions(false);
    setPlayCount(0);
    if (currentAudioIndex + 1 < audioFiles.length) {
      loadQuestions(currentAudioIndex + 1);
    }

    if (isFinish) {
      setSubmitScores(true);
    }
    window.scrollTo(0, 0);
  };

  if (currentAudioIndex === audioFiles.length) {
    return null; // No more audio files to show questions for
  } else {

  }

  const renderButtons = () => {
    let isFinish = false;
    if (currentAudioIndex === audioFiles.length - 1) {
      isFinish = true;
    }

    return (
      <SoftBox mt={1} mb={2}>
        <SoftBox mt={2} mb={1}>
          <SoftButton
            variant="contained"
            color="gt_primary"
            onClick={() => handleAnswerSelect(isFinish)}
            fullWidth
            disabled={
              selectedAnswers.length !== listeningQuestions.length ||
              selectedAnswers.includes("")
            }
            sx={{
              "&.Mui-disabled": {
                opacity: "0.6",
              },
            }}
          >
            {currentAudioIndex === audioFiles.length - 1 ? "Submit" : "Next"}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    );
  };

  return (
    <Container>
      <SoftBox mt={1} mb={1}>
        <SoftTypography variant="body1" fontWeight="bold" color="dark">
          Listening
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1} >
        <SoftTypography variant="body2" fontWeight="medium" color="dark">
          Press play to listen to the recording below
        </SoftTypography>
      </SoftBox>
      {audioFiles.length > 0 && (
        <SoftBox pt={1} px={1}>
          {currentAudioIndex > 0 && (<SoftBox mt={1} mb={1}>
            <SoftTypography variant="h6" fontWeight="medium" color="dark">
              Audio {currentAudioIndex + 1} of {audioFiles.length}
            </SoftTypography>
          </SoftBox>)}

          {playCount < 2 ? (
            <AudioWrapper>
              <audio
                controls
                style={{ width: "100%", margin: "1rem 0" }}
                // onPause={handlePause}
                onEnded={handleAudioEnd}
              >
                <source
                  src={audioFiles[currentAudioIndex].audio_file}
                  type="audio/mp3"
                />
              </audio>
            </AudioWrapper>
          ) : (
            <SoftBox mx={2} mt={1} mb={1} bgColor="warning">
              <SoftTypography variant="h6" fontWeight="medium" color="dark">
                Audio playback has been disabled after two plays.
              </SoftTypography>
            </SoftBox>
          )}

          <SoftBox mb={1}>
            <SoftTypography variant="body2" fontWeight="medium" color="dark">
              Answer the following questions about the scenario described in the recording
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      )}
      <SoftBox pt={1}>
        {listeningQuestions.length > 0 && (
          <SoftBox>
            {listeningQuestions.map((question, index) => (
              <Card
                mb={2}
                variant="outlined"
                style={{ marginBottom: "1rem", paddingBottom: "1rem" }}
              >
                <SoftBox lineHeight={0}>
                  <SoftBox mb={1} pl={1} bgColor="grey-100">
                    <SoftTypography
                      variant="h6"
                      fontWeight="medium"
                      color="dark"
                      style={{ paddingBottom: "8px" }}
                    >
                      {question.question}
                    </SoftTypography>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "5px",
                        height: "15px",
                        fontWeight: 200,
                        fontSize: "0.8rem",
                        marginTop: "2px",
                        marginBottom: "4px",
                        bgColor: "grey-100",
                      }}
                    >
                      Q {index + 1} of {listeningQuestions.length}
                    </div>
                  </SoftBox>

                  <SoftBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    mt={0.25}
                    pt={0.5}
                  >
                    {question.options.map((choice, choiceIndex) => (
                      <SoftBox
                        component="li"
                        display="flex"
                        alignItems="center"
                        borderRadius="lg"
                        p={0.25}
                        px={2.5}
                        mb={0}
                      >
                        <FormControlLabel
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          control={
                            <Radio
                              id={`answer-${index}-${choiceIndex}`}
                              name={`answer-${index}`}
                              value={choice}
                              checked={selectedAnswers[index] === choice}
                              onChange={(e) => handleAnswer(e, choice, index)}
                              sx={{
                                "&.MuiRadio-root": {
                                  height: "18px",
                                  width: "18px",
                                },
                              }}
                            />
                          }
                          label={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                lineHeight: "1rem",
                                paddingLeft: "0",
                              }}
                            >
                              <SoftTypography
                                variant="body2"
                                fontWeight="medium"
                                color="dark"
                                ml={0.5}
                              >
                                {choice}
                              </SoftTypography>
                            </div>
                          }
                        />
                      </SoftBox>
                    ))}
                  </SoftBox>
                </SoftBox>
              </Card>
            ))}
          </SoftBox>
        )}
      </SoftBox>
      {renderButtons()}
    </Container>
  );
}
