import React from "react";
import Login1 from "./components/Login1";
import CandidateList from "./components/CandidateList";
import QuestionsUploader from "./components/QuestionsUploader";
import ListeningUploader from "./components/ListeningUploader";
import ReadingPassageUploader from "./components/ReadingPassageUploader";


import Details from "./components/Details";
import Dashboard from "./components/DashBoard";
import Assessment from "./components/Assessment";
import Review from "./components/Review";
import AddCandidate from "./components/AddCandidate";
import AddUser from "./components/AddUser";
import ManageQuestions from "./components/ManageQuestions";


const routes = [
  {
    type: "collapse",
    name: "upload_questions",
    key: "upload_questions",
    route: "/upload_questions",
    component: <QuestionsUploader />,
    noCollapse: true,
    protected: false,
  },
  {
    type: "collapse",
    name: "RootLogin",
    key: "rootLogin",
    route: "/upload_listening",
    component: <ListeningUploader />,
    noCollapse: true,
    protected: false,
  },
  {
    type: "collapse",
    name: "upload_passages",
    key: "upload_passages",
    route: "/upload_passages",
    component: <ReadingPassageUploader />,
    noCollapse: true,
    protected: false,
  },
  {
    type: "collapse",
    name: "RootLogin",
    key: "rootLogin",
    route: "/",
    component: <Login1 />,
    noCollapse: true,
    protected: false,
  },
  {
    type: "collapse",
    name: "Login",
    key: "login",
    route: "/login",
    component: <Login1 />,
    noCollapse: true,
    protected: false,
  },
  {
    type: "collapse",
    name: "Questions",
    key: "manager_questions",
    route: "/manager_questions",
    component: <ManageQuestions />,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Candidates",
    key: "candidates",
    route: "/candidates",
    component: <CandidateList />,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Details",
    key: "Details",
    route: "/details/:q",
    component: <Details />,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Region",
    key: "Region",
    route: "/region/:q",
    component: <Assessment />,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Assessments",
    key: "Details",
    route: "/assessment/:q",
    component: <Assessment />,
    noCollapse: true,
    protected: false,
  },
  {
    type: "collapse",
    name: "review",
    key: "review",
    route: "/review/:q",
    component: <Review />,
    noCollapse: true,
    protected: false,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "Dashboard",
    route: "/dashboard",
    component: <Dashboard />,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "AddUser",
    key: "AddUser",
    route: "/adduser",
    component: <AddUser />,
    noCollapse: true,
    protected: false,
  },
  {
    type: "collapse",
    name: "AddCandidate",
    key: "AddCandidate",
    route: "/addcandidate",
    component: <AddCandidate />,
    noCollapse: true,
    protected: true,
  },
];

export default routes;
