import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  Route,
  Routes,
} from "react-router-dom";

// import theme from "./assets/theme";
import themeRTL from "./assets/theme/theme-rtl";
import { CssBaseline } from "@mui/material";
import routes from "./routes";

import { ProtectedRoute } from "./ProtectedRoute";

export default function App() {

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {

      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.protected) {
          return (
            <Route key={route.key} element={<ProtectedRoute />}>
              <Route path={route.route} element={route.component} />
            </Route>
          );
        }
        return (
          <Route path={route.route} element={route.component} key={route.key} />
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={themeRTL}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}
      </Routes>
    </ThemeProvider>
  );
}
