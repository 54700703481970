import { db } from "../firebase";
import {
    doc,
    updateDoc,
    collection,
    getDocs,
    query,
    where,
    orderBy,
    limit,
    startAfter,
    Timestamp,
} from "firebase/firestore/lite";

const updateUserAssessment = () => {
    const q = query(
        collection(db, "users"),
        where("userType", "==", "Candidate"),
    );

    getDocs(q).then((userSnapshot) => {
        userSnapshot.docs.forEach(async (userDoc) => {
            const user = userDoc.data();
            user.id = userDoc.id;

            const q1 = query(
                collection(db, "assessments"),
                where("userId", "==", user.id),
                orderBy("createdAt", "desc"),
                limit(1) // Only get the latest assessment
            );

            const querySnapshot = await getDocs(q1);
            if (!querySnapshot.empty) {
                const assessment = querySnapshot.docs[0].data();

                const { reading_similarity, vocabScore, listeningScore } = assessment;

                const userRef = doc(db, "users", user.id);
                console.log('This is very critical. how to invoke this', user.id, reading_similarity, vocabScore, listeningScore)
                if (reading_similarity, vocabScore, listeningScore) {
                    await updateDoc(doc(collection(db, "users"), user.id), {
                        reading_similarity: reading_similarity,
                        vocabScore: vocabScore,
                        listeningScore: listeningScore
                    }).then((userDocRef) => {
                        console.log("Updated the user ", userDocRef);
                    });
                }

            }
        });
    });

}
export default updateUserAssessment
