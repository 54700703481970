import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AppBar, Box, Card, IconButton, Link, Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { auth } from "../firebase";
import { navbar } from "./Navbars/DashboardNavbar/styles";
import Headroom from "react-headroom";
import logo from "../../public/logo/GlowSkillsLogo.png";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SoftBox from "./SoftBox";
import { navbarIconButton } from "./helper";
import MenuIcon from "@material-ui/icons/Menu";

function Banner({ showBackButton, linkTxt, showLogoutButton }) {
  const theme = useTheme();
  const history = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const handleQuestions = () => {
    history("/manager_questions");
  };


  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        // Sign-out successful, redirect to login page
        localStorage.removeItem("prevPath");
        localStorage.removeItem("user");
        localStorage.removeItem("loggedIn");
        window.location.href = "/login";
      })
      .catch((error) => {
        // An error happened
        console.error(error);
      });
  };

  const handleGoBack = () => {
    history(-1);
  };

  const isMobile = theme.breakpoints.down("sm");
  const hasHistory = location.pathname !== "/";
  function handleClick() {
    window.location.reload();
  }
  const absolute = true;
  const transparentNavbar = false;
  const light = true;
  return (
    <AppBar
      position="static"
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Headroom
        style={{
          webkitTransition: "all .4s ease-in-out",
          mozTransition: "all .5s ease-in-out",
          oTransition: "all .5s ease-in-out",
          transition: "all .5s ease-in-out",
          zIndex: "1000",
          paddingRight: '0.5rem !important',
        }}
      >
        {/* <Headroom> */}
        <Card>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 0.5,
              bgcolor: "primary.default",
              color: "primary.contrastText",
            }}
          >
            {linkTxt ? (
              <Link
                to="/"
                onClick={handleClick}
                style={{ textDecoration: "none", color: "white" }}
              >
                <SoftBox
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    bgcolor: "primary.default",
                    color: "primary.contrastText",
                  }}
                >
                  <SoftBox
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "35%",
                      marginRight: "16px",
                    }}
                  >
                    <img
                      src={logo}
                      alt="Logo"
                      style={{
                        height: "30px",
                        marginLeft: "10px",
                        marginRight: "8px",
                      }}
                    />
                  </SoftBox>
                </SoftBox>
              </Link>
            ) : (
              <SoftBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  bgcolor: "primary.default",
                  color: "primary.contrastText",
                }}
              >
                <SoftBox
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "35%",
                    marginRight: "16px",
                  }}
                >
                  <img
                    src={logo}
                    alt="Logo"
                    style={{
                      height: "30px",
                      marginLeft: "10px",
                      marginRight: "8px",
                    }}
                  />
                  
                </SoftBox>
              </SoftBox>
            )}

            {showLogoutButton && (
              <>
                {/* Hamburger Menu Icon */}
                <IconButton
                  size="small"
                  color="light"
                  sx={navbarIconButton}
                  onClick={handleMenuOpen}
                  style={{ marginRight: '16px' }}
                >
                  <MenuIcon fontSize="large" style={{ fontWeight: "bold", color: "#777777" }} />
                </IconButton>
                {/* Hamburger Menu */}
                <Menu
                  id="menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  {/* <MenuItem onClick={handleQuestions}>Manage Questions</MenuItem> */}
                </Menu>
              </>
            )}

            {isMobile && hasHistory && showBackButton && (
              <IconButton
                size="small"
                // color="inherit"
                color="light"
                sx={navbarIconButton}
                aria-controls="logout"
                aria-haspopup="true"
                variant="contained"
                onClick={handleGoBack}
                style={{ marginRight: '16px' }}
              >
                <ArrowBackIosIcon
                  fontSize="large"
                  style={{ fontWeight: "bold", color: "#777777" }}
                />
              </IconButton>
            )}
          </Box>
        </Card>
      </Headroom>

    </AppBar>
  );
}

export default Banner;
