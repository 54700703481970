import styled, { createGlobalStyle } from "styled-components";


export const GlobalStyle = createGlobalStyle`
body {
  box-sizing: border-box;
  font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Ubuntu", "Helvetica Neue", sans-serif !important;
  margin: 0;
  margin: 0 auto;
  padding: 0;
  min-height: 100%;
  min-height: -webkit-fill-available;
}

html {
  min-height: 100%;
  height: -webkit-fill-available;
}
`;

export const Theme = {
  colors: { primary: "#000000", secondary: "#FFFFFF", tertiary: "#ADD8E6", light: '#F4F4F4', bs_black: '#12263F', bs_blue: '#2c7be5'},
  fontSizes: { title: "clamp(1.25rem, 5vw, 4rem)", body: "1rem" },
  mediaQuerySmall: "320px",
  mediaQueryMedium: "600px",
  mediaQueryLarge: "800px",
};

export const DivFlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DropdownLabel = styled.label`
  font-weight: 900;
`;
